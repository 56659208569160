import { createSlice } from "@reduxjs/toolkit";
import { addressDetailsAction, getListAddressAction } from "../Action/AddresAction";

const initialState = {
    addressList: [],
    addressDetails: {}
}

const addressSlice = createSlice({
    name: 'address',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListAddressAction.fulfilled, (state, action) => {
                state.addressList = action?.payload;
            })
            .addCase(addressDetailsAction.fulfilled, (state, action) => {
                state.addressDetails = action?.payload?.data;
            })
    },
});

export default addressSlice.reducer;