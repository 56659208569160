import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddNewAddressModal from "../Modals/addNewAddressModal";

const CartAddres = ({ formik }) => {
  const dispatch = useDispatch();
  const { addressList } = useSelector((state) => state.address);
  const [showNewAddress, setShowNewAddress] = useState(false);
  const handleCloseNewAddress = () => setShowNewAddress(false);

  useEffect(() => {
    if (addressList?.data?.length > 0 && !formik.values.selectedAddress) {
      let addressWithTypeZero = addressList?.data.find((res) => {
        if (res?.type === 0) {
          console.log("Address with type 0 found:", res);
          return res;
        }
      });
      if (addressWithTypeZero) {
        formik.setFieldValue('selectedAddress', addressWithTypeZero._id);
      } else {
        formik.setFieldValue('selectedAddress', addressList.data[0]._id);
      }
    }
  }, [addressList, formik.values.selectedAddress, formik]);

  return (
    <>
      <div className="delievery-address">
        <div className="delivery-top" onClick={() => setShowNewAddress(true)}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.57781 16C7.02191 15.7375 6.52768 15.4115 6.38188 14.7591C6.33959 14.5702 6.31977 14.3724 6.31889 14.1786C6.3136 12.8456 6.31625 11.5123 6.31625 10.1794C6.31625 10.0278 6.31625 9.87673 6.31625 9.6816C6.04622 9.6816 5.81144 9.6816 5.5771 9.6816C4.28602 9.6816 2.99494 9.68732 1.7043 9.67939C0.718926 9.67367 -0.0065626 8.94113 4.47684e-05 7.98042C0.00665213 7.04394 0.732581 6.32373 1.69814 6.31801C3.07335 6.31008 4.44856 6.3158 5.82378 6.3158C5.97442 6.3158 6.12463 6.3158 6.3158 6.3158C6.3158 5.88633 6.3158 5.49737 6.3158 5.10798C6.3158 3.97151 6.30964 2.8346 6.31801 1.69814C6.32506 0.732581 7.04394 0.00665213 7.98042 4.47685e-05C8.94113 -0.0065626 9.67455 0.718926 9.67939 1.70386C9.68688 3.21871 9.6816 4.734 9.6816 6.31625C9.8437 6.31625 9.99038 6.31625 10.1375 6.31625C11.3585 6.31625 12.5813 6.35897 13.7993 6.30303C14.8402 6.25546 15.5952 6.5845 16 7.57781C16 7.8584 16 8.13944 16 8.42003C15.5961 9.40981 14.845 9.74238 13.8055 9.69481C12.5897 9.63931 11.37 9.6816 10.1516 9.6816C10.0036 9.6816 9.85559 9.6816 9.6816 9.6816C9.6816 11.2057 9.68424 12.6739 9.67895 14.142C9.67807 14.349 9.65869 14.5605 9.6142 14.7627C9.4706 15.4155 8.97196 15.7357 8.42003 16C8.13944 16 7.8584 16 7.57781 16Z"
              fill="#171717"
            />
          </svg>
          <h3>Add New Address</h3>
        </div>
        <div className="delivery-bottom">
          {Array.isArray(addressList?.data) && addressList?.data?.length > 0 ? (
            addressList?.data.map((res, index) => {
              return (
                <div className="form-check" key={res?._id}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="selectedAddress"
                    id={`address-${res._id}`}
                    value={res._id}
                    checked={formik.values.selectedAddress === res._id}
                    onChange={() => formik.setFieldValue('selectedAddress', res._id)}
                  />
                  <label className="form-check-label" htmlFor={`address-${res._id}`}>
                    {res?.type === 1 ? 'Other' : res?.type === 2 ? 'Office' : 'Home'}
                    <p>{res?.full_address}</p>
                  </label>
                </div>
              );
            })
          ) : (
            <p className="mb-0"> No Address Found</p>
          )}
        </div>

      </div>

      <AddNewAddressModal
        showNewAddress={showNewAddress}
        handleCloseNewAddress={handleCloseNewAddress}
      />
    </>
  );
};

export default CartAddres;
