import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { promoCodeAction } from '../Redux/Action/CartAction';

const PromoCodeModal = ({ show, setShow, formik, setPromoId }) => {
    const dispatch = useDispatch();
    const { promoCodeList } = useSelector((state) => state.cart)

    console.log("promoCodeList", promoCodeList)

    useEffect(() => {
        dispatch(promoCodeAction())
    }, [])

    const handlePromoCode = (id, code) => {
        setPromoId(id)
        formik.setFieldValue('promoCode', code);
        setShow(false)
    }

    return (
        <>
            <Modal
                className="cmn-modal"
                show={show}
                onHide={() => setShow(false)}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h2 className="heading-25 mb-4 px-4">Promo Code</h2>
                    <div className="promocodes-wrap">
                        {
                            Array?.isArray(promoCodeList?.data) && promoCodeList?.data?.length > 0 ?
                                promoCodeList?.data?.map((res) => {
                                    return <>
                                        <div className="promocode-box mb-3" key={res?._id}>
                                            <div className="promocode-top">
                                                <h2>{res?.discount_type === 1 ? `Percentage ${res?.discount} %` : `Flat $${res?.discount}`} OFF using Credit card</h2>
                                                <p>Save {res?.discount_type === 1 ? `${res?.discount} %` : `$${res?.discount}`} with this code</p>
                                                <h3>{res?.code || 'N/A'}</h3>
                                            </div>
                                            <div className="promocode-bottom">
                                                <p>
                                                    {res?.description || 'N/A'}
                                                </p>
                                                <div className="d-flex justify-content-center">
                                                    <button type='button' onClick={() => handlePromoCode(res?._id, res?.code)}>Tap to Apply</button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }) :
                                <p>No Promo Code Available</p>
                        }


                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PromoCodeModal