import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import Googlemaps from "../Components/Googlemaps";

const PickupLocationModal = ({ showLocation, setShowLocation }) => {
    return (
        <>
            <Modal
                className="cmn-modal"
                show={showLocation}
                onHide={() => setShowLocation(false)}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="cmn-form-fields px-4">
                        <div className="address-main-sec">
                            <div className="d-flex justify-content-between mb-3">
                                <h2 className="heading-25">Pickup Location</h2>
                            </div>
                            <div className="newaddress-main">
                                <Row>
                                    <Col lg={12}>
                                        <Googlemaps />
                                    </Col>
                                    <Col lg={12}>
                                        <div className="pickup-address">
                                            <div className="d-flex align-items-center justify-content-between mt-4">
                                                <h3>Maracon Bakers</h3>
                                                <img
                                                    src={
                                                        require("../Assets/images/telephonee.svg").default
                                                    }
                                                />
                                            </div>
                                            <div className="pickup">
                                                <h4>Pickup</h4>
                                                <p>
                                                    Plot No.209, Kauri Hills, Madhapur, Telangana 500033,
                                                    Ph: +91 234567890
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <button type="button" className="cmn-red-btn mb-0 w-100">
                                            Start
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default PickupLocationModal