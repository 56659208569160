import React from 'react';

const ProductDetailsDesCard = ({ data }) => {
  return (
    <>
      <p>{data?.description || 'N/A'}</p>
      <h5>*It will take {data?.days_for_prepration || '0'} days for preparation.</h5>
      <h6>
        Allergens: <span>{data?.allergens || 'N/A'}</span>
      </h6>
      <div className="additions mt-2 mb-4">
        <ul>
          <li>
            <img src={data?.dietary === 1 ? require("../Assets/images/leaf.png") : require("../Assets/images/strawberry.png")} alt="strawberry" />
          {data?.dietary === 1 ? `Vegetarian` : data?.dietary === 2 ? `Vegan` : data?.dietary === 3 ? `Gluten-free` : `Sugar-free`}
          </li>
        </ul>
      </div>
    </>
  );
};

export default ProductDetailsDesCard;
