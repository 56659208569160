const CategoryName = {
    GOODTOGO: "goodtogo",
    MOSTPOPULAR: "mostpopular",
    FAVOURITE: "favourite",
    ALLDELIGHT: "alldelight"

}

const HomeTitles = {
    MOSTPOPULAR: "Most Popular",
    FAVOURITES: "Favorties",
    PROSHOPS: "Pro Shops",
    HOMEBAKERS: "Home Bakers",
    TRENDING: "Trending",
    NEARBYYOU: "Nearby You"
}

export {
    CategoryName,
    HomeTitles
}