import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ImagePath } from "../utilis/ImageUrl";
import ShopStatus from "../CommonComponent/ShopTimeStatus";
import { useDispatch } from "react-redux";
import { favortieAction, listShopAction, shopLikeAction, shopViewAllAction } from "../Redux/Action/HomeAction";
import { HomeTitles } from "../utilis/enums";
import InfiniteScroll from "react-infinite-scroll-component";

export default function ShopCard({ title, setTabIndex, tabIndex }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const limit = 10;
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [items, setItems] = useState([]);
  const [likes, setLikes] = useState({});

  useEffect(() => {
    const initialLikes = {};
    items.forEach((shop) => {
      initialLikes[shop._id] = shop.is_shop_liked;
    });
    setLikes(initialLikes);
  }, [items]);

  const SingleProduct = (id) => {
    navigate(`/single-restaurant/${id}`);
  };

  const shopLikeApi1 = async (shopId) => {
    let payload = { shop_id: shopId };
    await dispatch(shopLikeAction(payload)).then((res) => {
      if (res?.payload?.status === 200) {
        if (title === HomeTitles.FAVOURITES) {
          dispatch(favortieAction({ type: 1 }));
        } else {
          dispatch(listShopAction());
        }
        setLikes((prevLikes) => ({
          ...prevLikes,
          [shopId]: res?.payload?.data?.is_shop_liked,
        }));
      }
    });
  };

  const shopLikeApi = async (shopId) => {
    let payload = {
      shop_id: shopId,
    };
    await dispatch(shopLikeAction(payload)).then((res) => {
      if (res?.payload?.status === 200) {
        const isShopLiked = res?.payload?.data?.is_shop_liked;

        setLikes((prevLikes) => ({
          ...prevLikes,
          [shopId]: isShopLiked,
        }));

        if (!isShopLiked && title === HomeTitles.FAVOURITES) {
          setItems((prevItems) => prevItems.filter((item) => item._id !== shopId));
        } else {
          getApiCall(page - 1)
        }
      }
    });
  };

  const getApiCall = async (page) => {
    let response = [];
    if (title === HomeTitles.FAVOURITES || tabIndex) {
      const payload = {
        pagination: page,
        limit: limit,
        type: tabIndex === 0 ? 1 : 2
      };
      response = await dispatch(favortieAction(payload));
    } else {
      const titleToTypeMap = {
        [HomeTitles.MOSTPOPULAR]: 1,
        [HomeTitles.TRENDING]: 2,
        [HomeTitles.NEARBYYOU]: 3,
        [HomeTitles.PROSHOPS]: 4,
        [HomeTitles.HOMEBAKERS]: 5,
      };
      const type = titleToTypeMap[title];
      if (type) {
        const payload = {
          pagination: page,
          limit: limit,
          type: type
        };
        response = await dispatch(shopViewAllAction(payload));
      }
    }

    if (response?.payload?.status === 200) {
      const newItems = response?.payload?.data || [];
      setItems((prevItems) => {
        const allItems = [...prevItems, ...newItems];
        const uniqueItems = Array.from(new Map(allItems.map(item => [item._id, item])).values());
        return uniqueItems;
      });
      setHasMore(newItems.length === limit);
    }
  };

  useEffect(() => {
    console.log("runs")
    getApiCall(page);
  }, [page]);

  return (
    <Container >
      <InfiniteScroll
        dataLength={items.length}
        next={() => setPage((prevPage) => prevPage + 1)}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
      // endMessage={<p style={{ textAlign: 'center' }}><b>Yay! You have seen it all</b></p>}
      >
        <Row>
          {Array.isArray(items) && items.length > 0 ? (
            items.map((res) => (
              <Col lg={3} className="p-0" key={res._id}>
                <div
                  className={`slide-main ${res?.is_shop_closed ? `closed-add` : ''}`}
                >
                  <div
                    className="slide-img"
                    style={{ cursor: "pointer" }}
                    onClick={() => SingleProduct(res._id)}
                  >
                    <img
                      src={
                        res?.banner_image
                          ? ImagePath(res?.banner_image)
                          : require("../Assets/images/yeh-xintong-go3DT3PpIw.png")
                      }
                      alt={res?.name}
                    />
                  </div>
                  <div className="slide-desc">
                    <h2>{res?.name}</h2>
                    <ShopStatus
                      shopSchedule={res?.opening_hours}
                      type={"Home"}
                    />
                  </div>
                  <button
                    className={likes[res._id] ? "active-heart" : ""}
                    onClick={() => shopLikeApi(res._id)}
                  >
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.5445 0.75C11.5015 0.75 10.0015 3.75 10.0015 3.75C10.0015 3.75 8.50147 0.75 5.45747 0.75C2.98447 0.75 1.02647 2.82 1.00047 5.288C0.949469 10.413 5.06647 14.058 9.57947 17.12C9.7038 17.2045 9.85065 17.2496 10.001 17.2496C10.1513 17.2496 10.2981 17.2045 10.4225 17.12C14.9345 14.057 19.0525 10.413 19.0005 5.288C18.9755 2.819 17.0175 0.75 14.5445 0.75Z"
                        stroke="#A72A2F"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  {res?.is_shop_closed && (
                    <img
                      className="close-img"
                      src={require("../Assets/images/closed.svg").default}
                      alt="Closed"
                    />
                  )}
                </div>
              </Col>
            ))
          ) : (
            <p>No apidata Found</p>
          )}
        </Row>
      </InfiniteScroll>
    </Container>
  );
}
