import { createSlice } from "@reduxjs/toolkit";
import { dashboardAction, getUserProfile } from "../Action/AuthAction";

const initialState = {
    userDetails: {},
    dashboardData: {}
}

const authSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.userDetails = action?.payload?.data;
            })
            .addCase(dashboardAction.fulfilled, (state, action) => {
                state.dashboardData = action?.payload?.data;
                state.error = action.payload;
            });
    },
});

export default authSlice.reducer;