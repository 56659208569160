import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { ImagePath } from '../utilis/ImageUrl'
import { fullName } from '../utilis/commonFunction'
import { Link } from 'react-router-dom'
import { HomeTitles } from '../utilis/enums'

const ProfileHeader = ({ userProfileData }) => {
    return (
        <>
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic-two">
                    <img src={userProfileData?.profile_pic ? ImagePath(userProfileData?.profile_pic) : require("../Assets/images/Profile-icon.png")} />
                    {fullName(userProfileData?.first_name, userProfileData?.last_name)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/my-profile">
                        My Profile
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/cart">
                        My Cart
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/order-listing">
                        My Order
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/subscribed-products">
                        Subscribed Products
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="#">
                        Languages
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="#">
                        Invite Friend
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to={`/ViewAllPages?title=${HomeTitles.FAVOURITES}`}>
                        Favorites
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default ProfileHeader