import { createSlice } from "@reduxjs/toolkit";
import { getListOrderAction, orderDetailsAction } from "../Action/OrderAction";

const initialState = {
    orderList: [],
    orderDetails: {}
}

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListOrderAction.fulfilled, (state, action) => {
                state.orderList = action?.payload;
            })
            .addCase(orderDetailsAction.fulfilled, (state, action) => {
                state.orderDetails = action?.payload?.data;
            })
    },
});

export default orderSlice.reducer;