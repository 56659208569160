import React from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const LoginNowModal = ({ showTwo, setShowTwo }) => {
    return (
        <>
            <Modal
                className="cmn-modal"
                show={showTwo}
                onHide={() => setShowTwo(false)}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="modal-small-section">
                    <div className="cmn-modal-sec">
                        <p>You need to Login in order to add the products to cart</p>
                        <Link to="/login" className="second-cmn-button">
                            Login Now
                        </Link>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default LoginNowModal