import React, { useState, useEffect } from "react";
import useDebouncedValue from "../Hook/useDebounce";

const SearchBarComp = ({ onSearch}) => {
    const [searchValue, setSearchValue] = useState("");
    const debouncedSearchValue = useDebouncedValue(searchValue, 1000);

    const handleSearch = (val) => {
        setSearchValue(val);
    };

    useEffect(() => {
        if (onSearch) {
            onSearch(debouncedSearchValue);
        }
    }, [debouncedSearchValue]);

    return (
        <>
            <div className="search-input">
                <img
                    src={require("../Assets/images/search.svg").default}
                />
                <input type="search" placeholder="Search here" value={searchValue} onChange={(e) => handleSearch(e.target.value)} />
            </div>

        </>

    );
};

export default SearchBarComp;
