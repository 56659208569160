import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getListAddressAction } from '../Redux/Action/AddresAction';
import { editProfile, getUserProfile, userEditProfile } from '../Redux/Action/AuthAction';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import GoogleAutoComplete from '../Components/GoogleAutoComplete';
import GoogleMapModal from './googleMapModal';


const DeliveryDetailsModal = ({ show, handleClose }) => {
    const dispatch = useDispatch();
    const { addressList } = useSelector((state) => state.address)
    const [mapshow, setMapShow] = useState(false);
    const [intialState, setIntialState] = useState({
        label: '',
        lat: '',
        lng: '',
        value: {
            description: '',
            place_id: '',
        },
    });

    const [address, setAddress] = useState({
        label: '',
        lat: '',
        lng: '',
        value: {
            description: '',
            place_id: '',
        },
    });

    const handleSaveLocation = () => {
        if (address?.label) {
            handleSubmit(address)
        }
    };


    console.log("intialState", intialState)

    const handleSubmit = async (data) => {
        let formData = new FormData();
        formData.append("address", data.label);
        formData.append("place_id", data.value.place_id);
        formData.append("lat", data.lat);
        formData.append("lng", data.lng);
        await dispatch(editProfile(formData)).then((res) => {
            if (res?.payload?.status === 200) {
                dispatch(getUserProfile())
            } else {
                toast.error(res?.payload?.message);
            }
        });
        setMapShow(false);
    }

    useEffect(() => {
        if (show) {
            dispatch(getListAddressAction())
        }
    }, [show])

    console.log("addressList2222", addressList)

    const selectAddress = async (data) => {
        console.log("data", data)
        let formData = new FormData();
        formData.append('lat', data?.location.coordinates[1])
        formData.append('lng', data?.location.coordinates[0])
        formData.append('address', data?.full_address || "")
        formData.append('place_id', data?.place_id || "")
        await dispatch(editProfile(formData)).then((res) => {
            if (res?.payload?.status === 200) {
                dispatch(getUserProfile())
            } else {
                toast.error(res?.payload?.message)
            }
            handleClose()
        })
    }

    const handleOpenModal = () => {
        handleClose()
        setMapShow(true)
    }

    return (
        <>
            <Modal
                className="cmn-modal"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h2 className="heading-25 mb-4 px-4">Delivery Details</h2>
                    <div className="delivery-details px-4">
                        <div className="cmn-form-fields">
                            <Form.Group className="mb-3 form-inner">
                                <img
                                    src={require("../Assets/images/locico.svg").default}
                                />

                                <GoogleAutoComplete
                                    intialState={intialState}
                                    setIntialState={setIntialState}
                                    type={'setheaderlocation'}
                                    handleClose={handleClose}
                                />
                            </Form.Group>
                            <button
                                type="button"
                                className="current-location"
                                onClick={() => handleOpenModal()}
                            >
                                <img
                                    src={
                                        require("../Assets/images/smalllocation.svg").default
                                    }
                                />
                                Choose Current Location
                            </button>
                        </div>


                        <div className='mt-2'>
                            <h2 className="heading-25 mb-2">Select Address</h2>
                            {
                                Array.isArray(addressList?.data) && addressList?.data?.length > 0 ?
                                    addressList?.data.map((res, index) => {
                                        return <>
                                            <div className={`address-inner mb-2 ${res.type == 0 && 'active'}`} onClick={() => selectAddress(res)}>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h3>{res?.type == 1 ? 'Other' : res?.type == 2 ? 'Office' : 'Home'}</h3>
                                                </div>
                                                <p>
                                                    {res?.full_address}
                                                </p>
                                            </div>
                                        </>
                                    })
                                    :
                                    <p className='text-center'> No Address Added </p>
                            }

                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Google map Modal  */}

            <GoogleMapModal mapshow={mapshow} setMapShow={setMapShow} setAddress={setAddress} address={address} handleSaveLocation={handleSaveLocation} />

        </>
    )
}

export default DeliveryDetailsModal