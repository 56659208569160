const API_FILE_ROOT = "https://milcake-api.bosselt.com/public/"
// const API_FILE_ROOT = "http://192.168.0.125:4046/public/"

const ImagePath = (filename) => {
    if (filename?.startsWith('http')) {
        return filename
    } else {
        return `${API_FILE_ROOT}${filename}`
    }
}

export {
    ImagePath
}