import { createSlice } from "@reduxjs/toolkit";
import { deliveryChargesAction, detailsCartAction, listCartAction, promoCodeAction } from "../Action/CartAction";

const initialState = {
    cartList: [],
    cartDetails: {},
    deliveryList: [],
    promoCodeList: []
}

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(listCartAction.fulfilled, (state, action) => {
                state.cartList = action?.payload;
            })
            .addCase(detailsCartAction.fulfilled, (state, action) => {
                state.cartDetails = action?.payload?.data;
            })
            .addCase(deliveryChargesAction.fulfilled, (state, action) => {
                state.deliveryList = action?.payload;
            })
            .addCase(promoCodeAction.fulfilled, (state, action) => {
                state.promoCodeList = action?.payload;
            })
    },
});

export default cartSlice.reducer;