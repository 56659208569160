import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Googlemaps from "../Components/Googlemaps";
import { addAddressAction, addressDetailsAction, editAddressAction, getListAddressAction } from '../Redux/Action/AddresAction';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

const EditNewAddressModal = ({ editModalOpen, setEditModalOpen, addressId }) => {
    const dispatch = useDispatch();
    const [address, setAddress] = useState({
        label: '',
        lat: '',
        lng: '',
        value: {
            description: '',
            place_id: '',
        },
    });

    const [initialData, setIntialData] = useState({
        flat_no: '',
        landmark: '',
        type: '0',
    })

    const detailApiCall = (addressId) => {
        dispatch(addressDetailsAction({ id: addressId })).then((res) => {
            if (res?.payload?.status === 200) {
                let data = res?.payload?.data
                let newValues = {
                    flat_no: data?.flat_no,
                    landmark: data?.landmark,
                    type: data?.type?.toString() || '0'
                }
                setIntialData({ ...initialData, ...newValues })
                let addressVal = {
                    label: data?.full_address,
                    lat: data?.location?.coordinates[1],
                    lng: data?.location?.coordinates[0],
                    value: {
                        description: data?.full_address,
                        place_id: data?.place_id
                    }
                }
                setAddress({ ...address, ...addressVal })
            }
        })
    }

    useEffect(() => {
        if (addressId) {
            detailApiCall(addressId)
        }
    }, [addressId])

    const formik = useFormik({
        initialValues: initialData,
        enableReinitialize: true,
        validationSchema: Yup.object({
            flat_no: Yup.string().required('House/Flat Number is required'),
            landmark: Yup.string(),
            type: Yup.string().required('Please select an address type'),
        }),
        onSubmit: async (values) => {
            console.log('Form data', values);
            let payload = {
                flat_no: values?.flat_no,
                full_address: address.label,
                landmark: values?.landmark,
                type: Number(values?.type),
                place_id: address.value.place_id,
                lat: address.lat,
                lng: address.lng
            }
            await dispatch(editAddressAction({ id: addressId, formdata: payload })).then((res) => {
                console.log("address userEdit res", res);
                if (res?.payload?.status === 200) {
                    dispatch(getListAddressAction())
                    detailApiCall(res?.payload?.data?._id)
                    toast.success(res?.payload?.message);
                } else {
                    toast.error(res?.payload?.message);
                }
                // formik.resetForm();
                setEditModalOpen(false);
            })
        },
    });

    console.log("edit----------------", address)


    return (
        <>
            <Modal
                className="cmn-modal"
                show={editModalOpen}
                onHide={() => setEditModalOpen(false)}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="cmn-form-fields px-4">
                        <div className="address-main-sec">
                            <div className="d-flex justify-content-between mb-3">
                                <h2 className="heading-25">Edit Address</h2>
                            </div>
                            <div className="newaddress-main">
                                <Form onSubmit={formik.handleSubmit}>
                                    <Row>
                                        <Col lg={12}>
                                            <Googlemaps setAddress={setAddress} address={address} type="emptyField" />
                                        </Col>
                                        <Col lg={12}>
                                            <div className="address-fields">
                                                <Form.Group className="mb-1 mt-3">
                                                    <Form.Control
                                                        className="px-3"
                                                        type="text"
                                                        placeholder="House/Flat Number*"
                                                        name="flat_no"
                                                        value={formik.values.flat_no}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.flat_no && formik.errors.flat_no && (
                                                        <div className="text-danger">
                                                            {formik.errors.flat_no}
                                                        </div>
                                                    )}
                                                </Form.Group>
                                                <Form.Group className="mb-1 mt-3">
                                                    <Form.Control
                                                        className="px-3"
                                                        type="text"
                                                        placeholder="Landmark (Optional)"
                                                        name="landmark"
                                                        value={formik.values.landmark}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        isInvalid={formik.touched.landmark && formik.errors.landmark}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.landmark}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <div className="address-select">
                                                    <h3>Save as</h3>
                                                    <div className="d-flex">
                                                        <div className="select-address-box">
                                                            <input
                                                                type="radio"
                                                                name="type"
                                                                value={'0'}
                                                                checked={formik.values.type === '0'}
                                                                onChange={formik.handleChange}
                                                            />
                                                            <button type="button">Home</button>
                                                        </div>
                                                        <div className="select-address-box ms-3">
                                                            <input
                                                                type="radio"
                                                                name="type"
                                                                value={"1"}
                                                                checked={formik.values.type === '1'}
                                                                onChange={formik.handleChange}
                                                            />
                                                            <button type="button">Other</button>
                                                        </div>
                                                        <div className="select-address-box ms-3">
                                                            <input
                                                                type="radio"
                                                                name="type"
                                                                value={"2"}
                                                                checked={formik.values.type === '2'}
                                                                onChange={formik.handleChange}
                                                            />
                                                            <button type="button">Office</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                            <button type="submit" className="cmn-red-btn mb-0 w-100">
                                                Save & Proceed
                                            </button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EditNewAddressModal;
