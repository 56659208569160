import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ShopCard from "../Components/ShopCard";
import ProductCardFav from "../CommonComponent/ProductCardFav";
import { HomeTitles } from "../utilis/enums";

const AllPages = ({ title }) => {
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="pt-4">
        <div className="deals-sec">
          <div className="deal-cmn-heading mb-4">
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
              <div className="fav-tabs-top mb-4">
                <h2 className="heading-25" style={{ color: "#A72A2F" }}>
                  {title}
                </h2>

                {
                  title === HomeTitles.FAVOURITES &&
                  <TabList className="tabs-switch">
                    <Tab >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M0 5.34188C0.0618346 5.33754 0.123669 5.32937 0.185504 5.32937C5.38142 5.32878 10.5773 5.32882 15.7732 5.32882C15.8349 5.32882 15.8967 5.32882 16 5.32882C15.9758 5.68274 15.9944 6.03161 15.9201 6.35945C15.7903 6.9326 15.2699 7.32309 14.6753 7.33291C13.989 7.34428 13.3021 7.34517 12.6158 7.33244C12.0099 7.32124 11.5111 6.90921 11.3588 6.32574C11.3472 6.28108 11.3332 6.23705 11.3152 6.17541C11.194 6.7518 10.878 7.13475 10.3172 7.28584C10.1838 7.32178 10.0405 7.33413 9.90166 7.33455C8.64821 7.33813 7.39472 7.3367 6.14128 7.33666C5.33339 7.33662 4.86409 6.9738 4.66087 6.18278C4.63839 6.26229 4.62084 6.33223 4.59891 6.4007C4.42275 6.95059 3.93161 7.32553 3.34706 7.33367C2.67105 7.3431 1.9947 7.34315 1.31873 7.33388C0.687378 7.3252 0.18041 6.90382 0.0303069 6.28588C0.024456 6.26174 0.0103128 6.23958 0 6.21649C0 5.92497 0 5.63344 0 5.34188Z"
                          fill="#8391A1"
                        />
                        <path
                          d="M9.58103 4.21337e-05C9.80471 1.12497 10.0285 2.2499 10.252 3.37483C10.336 3.79709 10.4194 4.2194 10.5059 4.65561C8.82799 4.65561 7.16224 4.65561 5.47398 4.65561C5.78273 3.09936 6.09026 1.54972 6.39775 4.21337e-05C7.45883 4.21337e-05 8.51991 4.21337e-05 9.58103 4.21337e-05Z"
                          fill="#8391A1"
                        />
                        <path
                          d="M5.05579 4.21337e-05C4.92092 0.661331 4.78424 1.32224 4.65161 1.98395C4.48122 2.834 4.31365 3.68463 4.14628 4.53531C4.12961 4.62013 4.10478 4.67048 3.99866 4.6701C2.75048 4.6661 1.50226 4.6674 0.254031 4.66698C0.233784 4.66698 0.213496 4.66247 0.178979 4.65843C0.186009 4.61145 0.187693 4.567 0.199563 4.52545C0.575285 3.20827 0.9561 1.89252 1.32538 0.573566C1.40548 0.28731 1.5752 0.106345 1.84131 4.21337e-05C2.91279 4.21337e-05 3.98427 4.21337e-05 5.05579 4.21337e-05Z"
                          fill="#8391A1"
                        />
                        <path
                          d="M14.1375 4.21337e-05C14.4554 0.116668 14.5969 0.359948 14.6846 0.677721C15.0378 1.95837 15.4095 3.23389 15.774 4.51147C15.7865 4.55554 15.7945 4.60092 15.8079 4.6599C15.7471 4.66281 15.6965 4.66736 15.6459 4.66736C14.4394 4.66774 13.2328 4.66542 12.0263 4.67086C11.8948 4.67145 11.8511 4.63041 11.8264 4.50414C11.5377 3.03198 11.2429 1.56105 10.9492 0.0898712C10.9431 0.0593664 10.9318 0.0299571 10.923 0C11.9945 4.21337e-05 13.066 4.21337e-05 14.1375 4.21337e-05Z"
                          fill="#8391A1"
                        />
                        <path
                          d="M11.3148 7.48383C11.5932 7.71675 11.8951 7.88532 12.245 7.94831C12.3976 7.97578 12.5539 7.99424 12.7088 7.99554C13.3329 8.00077 13.9572 8.00536 14.5812 7.99386C14.8224 7.98939 15.0627 7.94153 15.3189 7.91166C15.3189 7.9551 15.3189 8.01084 15.3189 8.06658C15.3189 10.4511 15.3138 12.8357 15.3237 15.2202C15.3255 15.6555 15.0461 16.0031 14.5411 16C12.2006 15.9857 9.8599 15.9937 7.51928 15.9937C7.45828 15.9937 7.39733 15.9937 7.32089 15.9937C7.32089 15.9097 7.32089 15.8432 7.32089 15.7768C7.32089 13.8972 7.32131 12.0177 7.3198 10.1382C7.31971 10.0294 7.31879 9.91701 7.29286 9.81264C7.22513 9.53983 6.96954 9.33902 6.68592 9.33822C5.55724 9.33502 4.42852 9.33455 3.29984 9.33843C2.9782 9.33953 2.71521 9.58609 2.67728 9.90492C2.66747 9.98729 2.66617 10.0711 2.66613 10.1542C2.66554 12.0285 2.66566 13.9028 2.66566 15.7771C2.66566 15.8436 2.66566 15.91 2.66566 15.9937C2.37055 15.9937 2.09105 15.9937 1.81151 15.9937C1.65547 15.9937 1.49944 15.9946 1.3434 15.9934C0.958499 15.9904 0.675297 15.7148 0.660312 15.3294C0.658881 15.293 0.659723 15.2565 0.659723 15.2201C0.659723 12.8355 0.659723 10.451 0.659723 8.06646C0.659723 8.01071 0.659723 7.95501 0.659723 7.90239C1.5723 8.07905 2.47814 7.97827 3.37935 7.99074C3.822 7.99685 4.22293 7.83497 4.57239 7.5571C4.60034 7.53485 4.62985 7.51459 4.66399 7.48943C4.93393 7.71493 5.23035 7.8836 5.57563 7.94465C5.74354 7.97431 5.91549 7.99483 6.08567 7.99542C7.26634 7.99972 8.44717 7.98788 9.62771 8.0017C10.2489 8.00903 10.8303 7.92463 11.3148 7.48383ZM11.0015 9.33599C10.4556 9.33599 9.90965 9.33489 9.36375 9.33632C8.93832 9.33742 8.66555 9.59443 8.66042 10.0194C8.65259 10.6699 8.65267 11.3205 8.66033 11.971C8.66534 12.3963 8.93789 12.6552 9.3624 12.6557C10.4439 12.657 11.5253 12.6569 12.6067 12.6557C13.0314 12.6552 13.3047 12.3975 13.3102 11.9723C13.3186 11.3219 13.3184 10.6712 13.3103 10.0208C13.305 9.59486 13.0324 9.33746 12.6081 9.33632C12.0725 9.33489 11.537 9.33599 11.0015 9.33599Z"
                          fill="#8391A1"
                        />
                      </svg>
                      Shops
                    </Tab>
                    <Tab >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M0.0706174 4.3537C2.5146 5.43726 4.90522 6.49712 7.32271 7.56889C7.32271 10.3297 7.32271 13.1502 7.32271 15.9979C7.16738 15.9385 7.02422 15.8913 6.88708 15.8302C5.12814 15.0464 3.36459 14.2727 1.61374 13.471C0.563996 12.9903 0.0115964 12.147 0.00567314 10.9797C-0.0042166 9.01463 0.00080872 7.04942 0.00583292 5.08425C0.00646756 4.8506 0.0462368 4.6171 0.0706174 4.3537Z"
                          fill="#8391A1"
                        />
                        <path
                          d="M8.6638 16C8.6638 15.899 8.6638 15.8366 8.6638 15.7742C8.6638 13.0847 8.66533 10.3951 8.65994 7.7055C8.65962 7.54424 8.71277 7.49633 8.86017 7.43721C9.63056 7.12824 10.3917 6.7959 11.1561 6.47168C11.2433 6.43471 11.3306 6.39796 11.4495 6.34776C11.4495 6.89921 11.4456 7.41862 11.4506 7.93798C11.4549 8.38341 11.8387 8.68341 12.2705 8.58753C12.575 8.51997 12.773 8.24117 12.7748 7.86267C12.7779 7.22863 12.7816 6.59443 12.7716 5.9605C12.7692 5.80328 12.8196 5.72907 12.9613 5.66698C13.9314 5.2419 14.8976 4.80763 15.9054 4.35827C15.935 4.56149 15.9853 4.74777 15.9858 4.9342C15.9913 7.01059 16.0195 9.08762 15.9766 11.1632C15.9542 12.2437 15.3624 13.0228 14.3844 13.4708C12.9557 14.1252 11.517 14.7577 10.0822 15.3989C9.7219 15.5599 9.36121 15.7201 8.99931 15.8775C8.89941 15.9209 8.79443 15.9527 8.6638 16Z"
                          fill="#8391A1"
                        />
                        <path
                          d="M4.89332 1.15302C4.97302 1.11451 5.03321 1.08365 5.09482 1.05598C5.69518 0.786366 6.29513 0.515903 6.89639 0.248413C7.60449 -0.0666142 8.32369 -0.0897724 9.03294 0.223502C10.8444 1.02363 12.6522 1.83221 14.4553 2.65114C14.7267 2.77442 14.9649 2.97137 15.2706 3.16769C15.0755 3.25528 14.9516 3.31115 14.8275 3.36655C13.9801 3.74479 13.1339 4.12552 12.2837 4.49701C12.2005 4.53334 12.0756 4.54927 11.9987 4.51316C9.68398 3.42769 7.37285 2.33447 5.0614 1.24215C5.01211 1.21883 4.96493 1.19116 4.89332 1.15302Z"
                          fill="#8391A1"
                        />
                        <path
                          d="M10.5032 5.30697C9.84311 5.59421 9.21921 5.89155 8.57564 6.13695C8.11272 6.31345 7.63209 6.25635 7.17849 6.05393C5.08577 5.12005 2.99444 4.1832 0.902838 3.24688C0.866664 3.23068 0.833238 3.20822 0.739418 3.15537C1.0415 2.9641 1.29541 2.77065 1.57624 2.6326C2.08543 2.38232 2.6089 2.16089 3.1312 1.9387C3.20773 1.90615 3.32445 1.90609 3.39923 1.9412C5.71777 3.02938 8.03345 4.12377 10.3493 5.21778C10.3917 5.23781 10.4307 5.26469 10.5032 5.30697Z"
                          fill="#8391A1"
                        />
                      </svg>
                      Products
                    </Tab>
                  </TabList>
                }
              </div>

              <TabPanel>
                <ShopCard title={title} tabIndex={tabIndex} setTabIndex={setTabIndex} />
              </TabPanel>
              <TabPanel>
                <ProductCardFav title={title} tabIndex={tabIndex} setTabIndex={setTabIndex} />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </section>
    </>
  );
};

export default AllPages;
