import React, { useState, useEffect } from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ImagePath } from "../utilis/ImageUrl";
import { useDispatch } from "react-redux";
import { favortieAction, productLikeAction, productListAction } from "../Redux/Action/HomeAction";
import { HomeTitles } from "../utilis/enums";
import InfiniteScroll from "react-infinite-scroll-component";

export default function ProductCardFav({ title, tabIndex, setTabIndex }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const limit = 10;
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);
    const [items, setItems] = useState([]);
    const [likes, setLikes] = useState({});

    useEffect(() => {
        const initialLikes = {};
        items?.forEach((product) => {
            initialLikes[product._id] = product.is_product_liked;
        });
        setLikes(initialLikes);
    }, [items]);

    const SingleProduct = (id) => {
        navigate(`/single-dish/${id}`);
    };

    const productLikeApi = async (productId) => {
        let payload = {
            product_id: productId,
        };
        await dispatch(productLikeAction(payload)).then((res) => {
            if (res?.payload?.status === 200) {
                const isProductLiked = res?.payload?.data?.is_product_liked;

                setLikes((prevLikes) => ({
                    ...prevLikes,
                    [productId]: isProductLiked,
                }));

                // Refetch favorites or update the items based on the like/dislike status
                if (!isProductLiked) {
                    // If the product is disliked, remove it from the items array
                    setItems((prevItems) => prevItems.filter((item) => item._id !== productId));
                } else {
                    // Optionally refetch the favorites list if required after liking the product
                    dispatch(favortieAction({ type: 2 })).then((res) => {
                        if (res?.payload?.status === 200) {
                            const newItems = res?.payload?.data || [];
                            setItems((prevItems) => {
                                const allItems = [...prevItems, ...newItems];
                                const uniqueItems = Array.from(new Map(allItems.map(item => [item._id, item])).values());
                                return uniqueItems;
                            });
                        }
                    });
                }
            }
        });
    };



    const getApiCall = async (page) => {
        let response = [];
        if (title === HomeTitles.FAVOURITES || tabIndex) {
            const payload = {
                pagination: page,
                limit: limit,
                type: tabIndex === 0 ? 1 : 2
            };
            response = await dispatch(favortieAction(payload));
        }
        if (response?.payload?.status === 200) {
            const newItems = response?.payload?.data || [];
            setItems((prevItems) => {
                const allItems = [...prevItems, ...newItems];
                const uniqueItems = Array.from(new Map(allItems.map(item => [item._id, item])).values());
                return uniqueItems;
            });
            setHasMore(newItems.length === limit);
        }
    };

    useEffect(() => {
        getApiCall(page);
    }, [page]);


    return (
        <>
            <Container className="djksdhjk">
                <InfiniteScroll
                    dataLength={items.length}
                    next={() => setPage((prevPage) => prevPage + 1)}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                // endMessage={<p style={{ textAlign: 'center' }}><b>Yay! You have seen it all</b></p>}
                >
                    <Row>
                        {Array.isArray(items) && items?.length > 0 ?
                            items?.map((res) => {
                                return (
                                    <Col lg={3} className="p-0">
                                        <div
                                            className={`slide-main`}
                                            key={res?._id}
                                        >
                                            <div
                                                className="slide-img"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => SingleProduct(res?._id)}
                                            >
                                                <img
                                                    src={
                                                        res?.images?.length > 0
                                                            ? ImagePath(res?.images[0])
                                                            : require("../Assets/images/yeh-xintong-go3DT3PpIw.png")
                                                    }
                                                />
                                            </div>
                                            <div className="slide-desc">
                                                <h2>{res?.name}</h2>
                                                <p>{res?.description?.length > 100 ? res?.description.slice(0, 80) + "..." : res?.description}</p>
                                            </div>
                                            <button
                                                className={likes[res?._id] ? "active-heart" : ""}
                                                onClick={() => productLikeApi(res?._id)}
                                            >
                                                <svg
                                                    width="20"
                                                    height="18"
                                                    viewBox="0 0 20 18"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M14.5445 0.75C11.5015 0.75 10.0015 3.75 10.0015 3.75C10.0015 3.75 8.50147 0.75 5.45747 0.75C2.98447 0.75 1.02647 2.82 1.00047 5.288C0.949469 10.413 5.06647 14.058 9.57947 17.12C9.7038 17.2045 9.85065 17.2496 10.001 17.2496C10.1513 17.2496 10.2981 17.2045 10.4225 17.12C14.9345 14.057 19.0525 10.413 19.0005 5.288C18.9755 2.819 17.0175 0.75 14.5445 0.75Z"
                                                        stroke="#A72A2F"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </Col>
                                );
                            }) :
                            <p>No Data Found</p>
                        }
                    </Row>
                </InfiniteScroll>
            </Container>
        </>
    );
}
