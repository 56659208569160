import React from 'react'
import { Modal } from 'react-bootstrap'
import { Rating } from 'react-simple-star-rating'

const CommentsModal = ({ show, handleClose }) => {
    return (
        <>
            <Modal
                className="cmn-modal"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h2 className="heading-25 mb-4">Comments</h2>
                    {/* <div className="comments-main-section">
                        <div className="comments-popup mb-3">
                            <div className="comments-main">
                                <div className="d-flex">
                                    <img src={require("../Assets/images/user.png")} />
                                    <div>
                                        <h2>Ronaldo Richards</h2>
                                        <p>2 days ago</p>
                                    </div>
                                </div>
                                <Rating />
                            </div>
                            <p>
                                There are many variations of passages of Lorem Ipsum available,
                                but the majority have suffered alteration in some form.
                            </p>
                        </div>
                        <div className="comments-popup mb-3">
                            <div className="comments-main">
                                <div className="d-flex">
                                    <img src={require("../Assets/images/user.png")} />
                                    <div>
                                        <h2>Ronaldo Richards</h2>
                                        <p>2 days ago</p>
                                    </div>
                                </div>
                                <Rating />
                            </div>
                            <p>
                                There are many variations of passages of Lorem Ipsum available,
                                but the majority have suffered alteration in some form.
                            </p>
                        </div>
                        <div className="comments-popup mb-3">
                            <div className="comments-main">
                                <div className="d-flex">
                                    <img src={require("../Assets/images/user.png")} />
                                    <div>
                                        <h2>Ronaldo Richards</h2>
                                        <p>2 days ago</p>
                                    </div>
                                </div>
                                <Rating />
                            </div>
                            <p>
                                There are many variations of passages of Lorem Ipsum available,
                                but the majority have suffered alteration in some form.
                            </p>
                        </div>
                        <div className="comments-popup mb-3">
                            <div className="comments-main">
                                <div className="d-flex">
                                    <img src={require("../Assets/images/user.png")} />
                                    <div>
                                        <h2>Ronaldo Richards</h2>
                                        <p>2 days ago</p>
                                    </div>
                                </div>
                                <Rating />
                            </div>
                            <p>
                                There are many variations of passages of Lorem Ipsum available,
                                but the majority have suffered alteration in some form.
                            </p>
                        </div>
                        <div className="comments-popup mb-3">
                            <div className="comments-main">
                                <div className="d-flex">
                                    <img src={require("../Assets/images/user.png")} />
                                    <div>
                                        <h2>Ronaldo Richards</h2>
                                        <p>2 days ago</p>
                                    </div>
                                </div>
                                <Rating />
                            </div>
                            <p>
                                There are many variations of passages of Lorem Ipsum available,
                                but the majority have suffered alteration in some form.
                            </p>
                        </div>
                        <div className="comments-popup mb-3">
                            <div className="comments-main">
                                <div className="d-flex">
                                    <img src={require("../Assets/images/user.png")} />
                                    <div>
                                        <h2>Ronaldo Richards</h2>
                                        <p>2 days ago</p>
                                    </div>
                                </div>
                                <Rating />
                            </div>
                            <p>
                                There are many variations of passages of Lorem Ipsum available,
                                but the majority have suffered alteration in some form.
                            </p>
                        </div>
                    </div> */}
                    <p>
                        Under Development
                    </p>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CommentsModal