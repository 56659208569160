import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ProductDetailsDesCard from '../CommonComponent/ProductDetailsDesCard';
import ProductDetailsSizeCard from '../CommonComponent/ProductDetailsSizeCard';
import ProductDetailsFillingCard from '../CommonComponent/ProductDetailsFillingCard';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import useAuthToken from '../Hook/useAuthToken';
import { addCartAction, editCartAction, listCartAction, municipalityAction, outOfStockAction } from '../Redux/Action/CartAction';
import OutOfStockModal from '../Modals/outOfStockModal';
import MuncipalityModal from '../Modals/muncipalityModal';

const steps = ['Step 1', 'Step 2', 'Step 3'];

const validationSchema = Yup.object({
  size_id: Yup.string().required('Size is required'),
  filling_id: Yup.string().required('Filling is required'),
});

function CustomStepper({ productDetails, handleShowTwo, productId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const tokenData = useAuthToken();
  const [showTwo, setShowTwo] = useState(false);
  const [formDataState, setFormDataState] = useState({})
  const [showModalOpen, setShowModalOpen] = useState(false)
  const { cartList } = useSelector((state) => state.cart)
  const matchProductId = cartList?.data?.find((res) => {
    return res?.product_id === productId && res?.quantity
  })



  const formik = useFormik({
    initialValues: {
      size_id: '',
      filling_id: '',
      request: '',
      quantity: 1,
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log('Form Values:', values);
      setFormDataState({ ...formDataState, ...values })
      if (!tokenData) {
        handleShowTwo();
      } else {
        await dispatch(outOfStockAction({ id: productId })).then((res) => {
          if (res?.payload?.status === 200) {
            if (res?.payload?.data?.is_out_of_stock == true) {
              setShowTwo(true)
            } else {
              dispatch(municipalityAction({ id: productId })).then((res) => {
                if (res?.payload?.data?.outside_municipality == true) {
                  setShowModalOpen(true)
                  setShowTwo(false)
                } else {
                  addToCartApi(values)
                }
              })
            }
          }
        })
      }
    },
  });

  useEffect(() => {
    if (matchProductId) {
      formik.setFieldValue('quantity', matchProductId?.quantity);
    }
  }, [matchProductId]);


  const addToCartApi = async (values) => {
    let payload = {
      product_id: productId,
      quantity: values?.quantity,
      size_id: values?.size_id,
      filling_id: values?.filling_id,
      request: values?.request
    }
    console.log("payload", payload)
    await dispatch(addCartAction(payload)).then((res) => {
      console.log("cart res", res)
      if (res?.payload?.status === 200) {
        toast.success("Added to Cart Successfully")
        navigate(`/cart`)
      } else {
        toast.error(res?.payload?.message)
      }
    })
  }

  const handleNext = () => {
    if (activeStep === 1) {
      if (!formik.values.size_id) {
        return toast.warn("Please select Size")
      } else {
        setActiveStep((prevStep) => prevStep + 1);
      }
    } else if (activeStep < steps.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handlefillingStep = () => {
    if (!formik.values.filling_id) {
      return toast.warn("Please choose Filling")
    }
  }

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <ProductDetailsDesCard data={productDetails} />;
      case 1:
        return (
          <ProductDetailsSizeCard
            data={productDetails}
            formik={formik}
          />
        );
      case 2:
        return (
          <ProductDetailsFillingCard
            data={productDetails}
            formik={formik}
          />
        );
      default:
        return <div>Unknown Step</div>;
    }
  };

  const handleQuantity = async () => {
    if (matchProductId && formik.values.quantity !== matchProductId?.quantity) {
      let formdata = {
        quantity: formik.values.quantity
      };
      const response = await dispatch(editCartAction({ id: matchProductId?._id, formdata }));
      if (response?.payload?.status === 200) {
        dispatch(listCartAction())
        toast.success(response?.payload?.message)
      } else {
        toast.error(response?.payload?.message);
      }
    }
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div className="step-content">{renderStepContent(activeStep)}</div>
          <div className="quantity-main">
            <h3>Quantity</h3>
            <div>
              <button
                type="button"
                onClick={() => formik.setFieldValue('quantity', formik.values.quantity - 1)}
                disabled={formik.values.quantity <= 1}
              >
                <img src={require("../Assets/images/minus.svg").default} alt="minus" />
              </button>
              <span>{formik.values.quantity}</span>
              <button
                type="button"
                onClick={() => formik.setFieldValue('quantity', formik.values.quantity + 1)}
              >
                <img src={require("../Assets/images/add.svg").default} alt="add" />
              </button>
            </div>
            {formik.touched.quantity && formik.errors.quantity ? (
              <div>{formik.errors.quantity}</div>
            ) : null}
          </div>

          {
            productDetails?.is_added_to_cart ?
              <div className="buttons">
                <button type="button" className="cmn-red-btn" onClick={handleQuantity} >
                  {formik.values.quantity === matchProductId?.quantity ? `Already in Cart` : `Confirm`}
                </button>
              </div> :
              <div className="buttons">
                {activeStep === steps.length - 1 ? (
                  <div className="d-flex align-items-center">
                    <button type="button" onClick={() => navigate('/subscribed-products')} className="subscribe-btn">
                      Subscribe
                    </button>
                    <button type="submit" className="cmn-red-btn ms-2" onClick={handlefillingStep}>
                      Add to Cart ${formik?.values?.quantity * productDetails?.price }
                    </button>
                  </div>
                ) : (
                  <div className="d-flex align-items-center gap-3">
                    {activeStep !== 0 && (
                      <button type="button" onClick={handleBack} className="cmn-red-btn">
                        Back
                      </button>
                    )}
                    <button type="button" onClick={handleNext} className="cmn-red-btn" disabled={activeStep === steps.length - 1}>
                      Proceed
                    </button>
                  </div>
                )}
              </div>
          }



        </div>
      </form>
      <OutOfStockModal showTwo={showTwo} setShowTwo={setShowTwo} setShowModalOpen={setShowModalOpen} formDataState={formDataState} productId={productId} />

      <MuncipalityModal showModalOpen={showModalOpen} setShowModalOpen={setShowModalOpen} formDataState={formDataState} productId={productId} />
    </>
  );
}

export default CustomStepper;
