import { configureStore } from '@reduxjs/toolkit';
import authSlice from './Slice/authSlice';
import addressSlice from './Slice/addressSlice';
import homeSlice from './Slice/homeSlice';
import cartSlice from './Slice/cartSlice';
import orderSlice from './Slice/orderSlice';

const store = configureStore({
    reducer: {
        authData: authSlice,
        address: addressSlice,
        home: homeSlice,
        cart: cartSlice,
        order: orderSlice
    }
});

export default store;