import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userDeleteAction } from "../Redux/Action/AuthAction";
import { toast } from "react-toastify";
import { deleteCartAction, listCartAction } from "../Redux/Action/CartAction";

const DeleteModal = ({
    deleteAccOpen,
    setDeleteAccOpen,
    type,
    id
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleDeleteAccount = async () => {
        if (type === "cart") {
            await dispatch(deleteCartAction({ id })).then((res) => {
                if (res?.payload?.status === 200) {
                    dispatch(listCartAction());
                    toast.success(res?.payload?.message);
                    handleClose()
                } else {
                    toast.error(res?.payload?.message);
                }
            });
        } else {
            await dispatch(userDeleteAction()).then((res) => {
                console.log("delete", res);
                if (res?.payload?.status === 200) {
                    toast.success(res?.payload?.message);
                    localStorage.clear()
                    handleClose()
                    window.location.href = '/';
                    window.location.reload();

                } else {
                    toast.error(res?.payload?.message);
                }
            })
        }
    }

    const handleClose = () => {
        setDeleteAccOpen(false)
    }


    return (
        <Modal
            show={deleteAccOpen}
            onHide={handleClose}
            className="comn-modal-set"
        >
            <Modal.Header className="border-none" closeButton>
                <Modal.Title className="text-center modal-title ">
                    {type === "cart" ? 'Product Remove' : 'Account Delete'}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className="text-center suspend-text">
                    {" "}
                    Are you sure you want to {type === "cart" ? 'Remove Product' : 'Delete Account'} ?
                </p>
            </Modal.Body>
            <Modal.Footer className="border-none justify-content-center modal-footer">
                <Button
                    onClick={() => handleDeleteAccount()}
                    variant="danger"
                    className="modal-close-btn "
                >
                    Yes
                </Button>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                    className="modal-f-btn "
                >
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteModal;
