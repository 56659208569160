import React from 'react'
import { Modal } from 'react-bootstrap'

const TransactionModal = ({ showTransaction, handleCloseTransaction }) => {
    return (
        <>
            <Modal
                className="cmn-modal"
                show={showTransaction}
                onHide={handleCloseTransaction}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h2 className="heading-25 mb-4 px-4">Transactions</h2>
                    <div className="transactions-main">
                        <div className="transaction-inner mb-3">
                            <div className="d-flex align-items-center">
                                <img src={require("../Assets/images/mastercard.png")} />
                                <div>
                                    <h3>Master Card</h3>
                                    <p>Dec 12 2021 at 10:00 pm</p>
                                </div>
                            </div>
                            <h4>$89</h4>
                        </div>
                        <div className="transaction-inner mb-3">
                            <div className="d-flex align-items-center">
                                <img src={require("../Assets/images/mastercard.png")} />
                                <div>
                                    <h3>Master Card</h3>
                                    <p>Dec 12 2021 at 10:00 pm</p>
                                </div>
                            </div>
                            <h4>$89</h4>
                        </div>
                        <div className="transaction-inner mb-3">
                            <div className="d-flex align-items-center">
                                <img src={require("../Assets/images/mastercard.png")} />
                                <div>
                                    <h3>Master Card</h3>
                                    <p>Dec 12 2021 at 10:00 pm</p>
                                </div>
                            </div>
                            <h4>$89</h4>
                        </div>
                        <div className="transaction-inner mb-3">
                            <div className="d-flex align-items-center">
                                <img src={require("../Assets/images/mastercard.png")} />
                                <div>
                                    <h3>Master Card</h3>
                                    <p>Dec 12 2021 at 10:00 pm</p>
                                </div>
                            </div>
                            <h4>$89</h4>
                        </div>
                        <div className="transaction-inner mb-3">
                            <div className="d-flex align-items-center">
                                <img src={require("../Assets/images/mastercard.png")} />
                                <div>
                                    <h3>Master Card</h3>
                                    <p>Dec 12 2021 at 10:00 pm</p>
                                </div>
                            </div>
                            <h4>$89</h4>
                        </div>
                        <div className="transaction-inner mb-3">
                            <div className="d-flex align-items-center">
                                <img src={require("../Assets/images/mastercard.png")} />
                                <div>
                                    <h3>Master Card</h3>
                                    <p>Dec 12 2021 at 10:00 pm</p>
                                </div>
                            </div>
                            <h4>$89</h4>
                        </div>
                        <div className="transaction-inner mb-3">
                            <div className="d-flex align-items-center">
                                <img src={require("../Assets/images/mastercard.png")} />
                                <div>
                                    <h3>Master Card</h3>
                                    <p>Dec 12 2021 at 10:00 pm</p>
                                </div>
                            </div>
                            <h4>$89</h4>
                        </div>
                        <div className="transaction-inner mb-3">
                            <div className="d-flex align-items-center">
                                <img src={require("../Assets/images/mastercard.png")} />
                                <div>
                                    <h3>Master Card</h3>
                                    <p>Dec 12 2021 at 10:00 pm</p>
                                </div>
                            </div>
                            <h4>$89</h4>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TransactionModal