import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Googlemaps from "../Components/Googlemaps";

const GoogleMapModal = ({ mapshow, setMapShow, setAddress, address, handleSaveLocation }) => {
    console.log("map", mapshow)
    return (
        <>
            <Modal
                className="cmn-modal"
                show={mapshow}
                onHide={() => setMapShow(false)}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="address-main">
                        <Googlemaps
                            setAddress={setAddress}
                            address={address}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleSaveLocation}
                        className="cmn-red-btn mb-0 mt-0"
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default GoogleMapModal