import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { addCartAction, municipalityAction } from '../Redux/Action/CartAction'
import { toast } from 'react-toastify'

const MuncipalityModal = ({ showModalOpen, setShowModalOpen, formDataState, productId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleAddToCart = async () => {
        let newValues = {
            product_id: productId,
            remove_cart: 1
        }
        let payload = { ...formDataState, ...newValues }
        await dispatch(addCartAction(payload)).then((res) => {
            console.log("cart res", res)
            if (res?.payload?.status === 200) {
                toast.success("Added to Cart Successfully")
                setShowModalOpen(false)
                navigate(`/cart`)
            } else {
                setShowModalOpen(false)
                toast.error(res?.payload?.message)
            }
        })
    }

    return (
        <>
            <Modal
                className="cmn-modal"
                show={showModalOpen}
                onHide={() => setShowModalOpen(false)}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="modal-small-section">
                    <div className="cmn-modal-sec">
                        <p>You are ordering from different muncipality then the products that are in Cart. </p>
                        <button className="second-cmn-button" onClick={handleAddToCart}>
                            Continue
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MuncipalityModal