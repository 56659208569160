import React from 'react'

const NotificationHeader = () => {
    return (
        <>
            <img src={require("../Assets/images/notify.svg").default} />
        </>
    )
}

export default NotificationHeader