import { createAsyncThunk } from "@reduxjs/toolkit";
import CommonApi from "../../Service/CommonApi";

const addCartAction = createAsyncThunk(
    'add/cart', async (reqBody, thunkAPI) => {
        try {
            const response = await CommonApi.post('/cart', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const listCartAction = createAsyncThunk(
    'list/cart', async (reqBody, thunkAPI) => {
        try {
            const response = await CommonApi.get('/cart', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const deleteCartAction = createAsyncThunk(
    'delete/cart', async (reqBody, thunkAPI) => {
        try {
            const response = await CommonApi.delete(`/cart/${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const detailsCartAction = createAsyncThunk(
    'details/cart', async (reqBody, thunkAPI) => {
        try {
            const response = await CommonApi.get(`/cart/${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const editCartAction = createAsyncThunk(
    'edit/cart', async (reqBody, thunkAPI) => {
        try {
            const response = await CommonApi.put(`/cart/${reqBody.id}`, reqBody.formdata)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const outOfStockAction = createAsyncThunk(
    'get/stock', async (reqBody, thunkAPI) => {
        try {
            const response = await CommonApi.get(`/out-of-stock/product/${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const municipalityAction = createAsyncThunk(
    'get/municipality', async (reqBody, thunkAPI) => {
        try {
            const response = await CommonApi.get(`/municipality/product/${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const deliveryChargesAction = createAsyncThunk(
    'get/delivery-charges', async (reqBody, thunkAPI) => {
        try {
            const response = await CommonApi.get(`/delivery-charges`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const instantDeliveryAction = createAsyncThunk(
    'get/instant-charges', async (reqBody, thunkAPI) => {
        try {
            const response = await CommonApi.get(`/delivery-charges?type=4`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const promoCodeAction = createAsyncThunk(
    'get/promo-code', async (reqBody, thunkAPI) => {
        try {
            const response = await CommonApi.get(`/promocodes`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })


export {
    addCartAction,
    listCartAction,
    deleteCartAction,
    detailsCartAction,
    editCartAction,
    outOfStockAction,
    municipalityAction,
    deliveryChargesAction,
    promoCodeAction,
    instantDeliveryAction
}