import React, { useEffect, useRef, useState } from 'react';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
const style = {
  maxWidth: "100%",
  height: "550px",
  overflowX: "hidden",
  overflowY: "hidden",
  position: "static"
 };

const GMapPolyLine = (props) => {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [shopLocation, setShopLocation] = useState(null);
  const mapRef = useRef(null);

  // 1. Get the current location (runs only once when the component mounts)
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setCurrentLocation({ lat, lng });
        },
        (error) => console.error(error)
      );
    }
  }, []); // Empty dependency array to run only once on mount

  // 2. Geocode the shop address and get shop location (runs only when shopAddress changes)
  useEffect(() => {
    if (props.shopAddress && props.google) {
      const geocodeAddress = (address) => {
        const geocoder = new props.google.maps.Geocoder();
        geocoder.geocode({ address }, (results, status) => {
          if (status === 'OK' && results[0]) {
            const location = results[0].geometry.location;
            setShopLocation({ lat: location.lat(), lng: location.lng() });
          } else {
            console.error('Geocode failed:', status);
          }
        });
      };

      geocodeAddress(props.shopAddress);
    }
  }, [props.shopAddress, props.google]); // Dependency array: runs when shopAddress or google is available

  // 3. Calculate and render the directions once both locations are set
  useEffect(() => {
    if (currentLocation && shopLocation && props.google) {
      const directionsService = new props.google.maps.DirectionsService();
      const directionsRenderer = new props.google.maps.DirectionsRenderer();

      const request = {
        origin: currentLocation,
        destination: shopLocation,
        travelMode: 'DRIVING',
      };

      directionsService.route(request, (result, status) => {
        if (status === 'OK') {
          directionsRenderer.setDirections(result);
          directionsRenderer.setMap(mapRef.current.map); // Attach to the map instance
        } else {
          console.error('Directions request failed:', status);
        }
      });
    }
  }, [currentLocation, shopLocation, props.google]); // Only runs when both locations and the google API are ready

  return (
    <div className="map-container">
    <Map style={style} 
      google={props.google}
      zoom={12}
      initialCenter={currentLocation || { lat: 0, lng: 0 }}
      ref={mapRef}
    >
      {/* Marker for current location */}
      {/* {currentLocation && <Marker position={currentLocation} title="Your Location" />} */}

      {/* Marker for shop location */}
      {/* {shopLocation && <Marker position={shopLocation} title="Shop Location" />} */}
    </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBY7WePV2Eg7cigEfbsFvjs1GzEHx6GXVA',
})(GMapPolyLine);
