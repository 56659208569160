import React, { useState } from "react";
import Layout from "../Components/Layout";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import backgroundimage from "../Assets/images/singleview.png";
import { Rating } from "react-simple-star-rating";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import AsNavFor from "../Components/SingleDishSlider";
import SingleItemsSlider from "../Components/SingleItemsSlider";
import Googlemaps from "../Components/Googlemaps";
export default function SingleDishTwo() {
  const [quantity, setQuantity] = useState(0);

  const incrementQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decrementQuantity = () => {
    setQuantity(quantity > 0 ? quantity - 1 : 0);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Layout>
      <Container>
        <Link to="/single-dish" className="mt-4 d-block">
          <img src={require("../Assets/images/backimg.svg").default} />
        </Link>
        <div className="single-dish-main">
          <Row>
            <Col lg={6}>
              <div className="single-product-detail">
                <AsNavFor />
              </div>
            </Col>
            <Col lg={6}>
              <div className="single-dish-description">
                <div className="d-flex justify-content-between align-items-center">
                  <h2>Cheese Cupcakes</h2>
                  <button className="like-btn">
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.5445 0.75C11.5015 0.75 10.0015 3.75 10.0015 3.75C10.0015 3.75 8.50147 0.75 5.45747 0.75C2.98447 0.75 1.02647 2.82 1.00047 5.288C0.949469 10.413 5.06647 14.058 9.57947 17.12C9.7038 17.2045 9.85065 17.2496 10.001 17.2496C10.1513 17.2496 10.2981 17.2045 10.4225 17.12C14.9345 14.057 19.0525 10.413 19.0005 5.288C18.9755 2.819 17.0175 0.75 14.5445 0.75Z"
                        stroke="#A72A2F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className="d-flex align-items-center mt-2 mb-2">
                  {" "}
                  <Rating />
                  <h3
                    className="ms-4 mb-0"
                    onClick={handleShow}
                    style={{ cursor: "pointer" }}
                  >
                    12 Comments
                  </h3>
                </div>
                <h4>$3.00</h4>
                <div className="cake-box">
                  <h3>Size</h3>
                  <p>Select any 1 option</p>
                  <div className="cake-size-inner mb-0">
                    <h4>Small</h4>
                    <div className="d-flex">
                      <span>$4.00</span>
                      <div class="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="cake-size-inner mb-0">
                    <h4>Medium</h4>
                    <div className="d-flex">
                      <span>$4.00</span>
                      <div class="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="cake-size-inner mb-0">
                    <h4>Large</h4>
                    <div className="d-flex">
                      <span>$4.00</span>
                      <div class="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cake-detail-input">
                  {" "}
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="1"
                    value="Add a Request"
                  />
                </div>
                <div className="quantity-main">
                  <h3>Quantity</h3>
                  <div>
                    <button onClick={decrementQuantity}>
                      {" "}
                      <img
                        src={require("../Assets/images/minus.svg").default}
                      />
                    </button>

                    <span>{quantity}</span>
                    <button onClick={incrementQuantity}>
                      <img src={require("../Assets/images/add.svg").default} />
                    </button>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <Link to="/subscribed-product" className="subscribe-btn">
                    Subscribe
                  </Link>
                  <Link to="/single-dish-three" className="cmn-red-btn ms-2">
                    Add to Cart $1.80
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              {" "}
              <div className="deals-sec mt-4">
                <div className="deal-cmn-heading mb-4">
                  <h2 className="heading-25">Similar Products</h2>
                  <span></span>
                </div>
                <SingleItemsSlider />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Modal
        className="cmn-modal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2 className="heading-25 mb-4">12 Comments</h2>
          <div className="comments-main-section">
            <div className="comments-popup mb-3">
              <div className="comments-main">
                <div className="d-flex">
                  <img src={require("../Assets/images/user.png")} />
                  <div>
                    <h2>Ronaldo Richards</h2>
                    <p>2 days ago</p>
                  </div>
                </div>
                <Rating />
              </div>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form.
              </p>
            </div>
            <div className="comments-popup mb-3">
              <div className="comments-main">
                <div className="d-flex">
                  <img src={require("../Assets/images/user.png")} />
                  <div>
                    <h2>Ronaldo Richards</h2>
                    <p>2 days ago</p>
                  </div>
                </div>
                <Rating />
              </div>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form.
              </p>
            </div>
            <div className="comments-popup mb-3">
              <div className="comments-main">
                <div className="d-flex">
                  <img src={require("../Assets/images/user.png")} />
                  <div>
                    <h2>Ronaldo Richards</h2>
                    <p>2 days ago</p>
                  </div>
                </div>
                <Rating />
              </div>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form.
              </p>
            </div>
            <div className="comments-popup mb-3">
              <div className="comments-main">
                <div className="d-flex">
                  <img src={require("../Assets/images/user.png")} />
                  <div>
                    <h2>Ronaldo Richards</h2>
                    <p>2 days ago</p>
                  </div>
                </div>
                <Rating />
              </div>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form.
              </p>
            </div>
            <div className="comments-popup mb-3">
              <div className="comments-main">
                <div className="d-flex">
                  <img src={require("../Assets/images/user.png")} />
                  <div>
                    <h2>Ronaldo Richards</h2>
                    <p>2 days ago</p>
                  </div>
                </div>
                <Rating />
              </div>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form.
              </p>
            </div>
            <div className="comments-popup mb-3">
              <div className="comments-main">
                <div className="d-flex">
                  <img src={require("../Assets/images/user.png")} />
                  <div>
                    <h2>Ronaldo Richards</h2>
                    <p>2 days ago</p>
                  </div>
                </div>
                <Rating />
              </div>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form.
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}
