import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addPromoCodeAction } from "../Redux/Action/OrderAction";

const ApplyPromoCode = ({ formik, handleShow, setBillState, billState, cartIds, promoId }) => {
  const dispatch = useDispatch();
  const [applyState, setApplyState] = useState(false);

  const promoApply = () => {
    if (formik.values.promoCode) {
      promoCodeApplyApi()
    } else {
      toast.warn("Please fill promo-code")
    }
  }

  console.log("formik", formik.values)

  const promoCodeApplyApi = async () => {
    let payload = {
      cart_ids: cartIds,
      schedule_id: formik?.values?.selectedDeliveryCharge,
      instant_delivery: formik?.values?.instant_delivery == true ? 1 : 0
    }
    if (applyState === false) {
      payload = { ...payload, promocode_id: promoId }
    }
    await dispatch(addPromoCodeAction(payload)).then((res) => {
      if (res?.payload?.status === 200) {
        setBillState(res?.payload?.data)
        setApplyState(!applyState)
      } else {
        toast.error(res?.payload?.message)
      }
    })
  }

  return (
    <>
      <Col lg={12}>
        <div className="promocode-sec">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Apply Promo Code</h2>
            <h4 onClick={handleShow}>View All Promocodes</h4>
          </div>

          {/* Promocode apply and remove here*/}
          {
            applyState ?
              <div className="promocode-inner promocode-applied">
                <div className="">
                  <h2>{billState?.discount_type == 2 && `$`} {billState?.discount || '0'} {billState?.discount_type == 1 && `%`} Discount</h2>
                  <p>*promo-code successfully applied</p>
                </div>
                <button type="button" className="border-none" onClick={promoApply}>Remove</button>
              </div>
              :
              <div className="promocode-inner">
                <h3>Type your promo code here</h3>
                <div className="d-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Here"
                    name="promoCode"
                    value={formik.values.promoCode}
                    onChange={formik.handleChange}
                  />
                  <button type="button" onClick={promoApply}>Apply</button>
                </div>
              </div>
          }

        </div>
      </Col>

    </>
  );
};

export default ApplyPromoCode;
