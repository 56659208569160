import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { replace, useNavigate } from "react-router-dom";

const LogOutModal = ({ isModalOpen, setIsModalOpen }) => {
  const navigate = useNavigate();

  const handleLogOut = () => {
    localStorage.clear();
    handleClose();
    // navigate("/", { replace: true });
    window.location.href = '/';
    window.location.reload();
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal show={isModalOpen} onHide={handleClose} className="comn-modal-set">
      <Modal.Header className="border-none" closeButton>
        <Modal.Title className="text-center modal-title ">Logout</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="text-center suspend-text">
          {" "}
          Are you sure you want to Log Out ?
        </p>
      </Modal.Body>
      <Modal.Footer className="border-none justify-content-center modal-footer">
        <Button
          onClick={() => handleLogOut()}
          variant="danger"
          className="modal-close-btn "
        >
          Yes
        </Button>
        <Button
          variant="secondary"
          onClick={handleClose}
          className="modal-f-btn "
        >
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LogOutModal;
