import React from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { Rating } from 'react-simple-star-rating'

const OrderFeedack = ({ showFeedback, setShowFeedback }) => {
    return (
        <>
            <Modal
                className="cmn-modal"
                show={showFeedback}
                onHide={() => setShowFeedback(false)}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h2 className="heading-25 mb-4">Order Feedback</h2>
                    <div className="feedback-main">
                        <div className="orders-card">
                            <div className="orderscard-top">
                                <Row>
                                    <Col lg={3}>
                                        <img src={require("../Assets/images/orders.svg").default} />
                                    </Col>
                                    <Col lg={9}>
                                        <h3>Order Id #90897</h3>
                                        <p>Placed on June 19 2024</p>
                                        <div className="d-flex">
                                            <h4>Item: 10 </h4>
                                            <h4>Price: $600</h4>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="orders-card mt-3">
                            <div className="orderscard-top">
                                <Row>
                                    <Col lg={3}>
                                        <img src={require("../Assets/images/cakes.png")} />
                                    </Col>
                                    <Col lg={9}>
                                        <h3>Iced Coffee & Milk</h3>
                                        <p>Regular</p>
                                        <div className="d-flex">
                                            <h6>Marcon Bakers</h6>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-4 mb-3">
                            <Rating initialValue={4} />
                        </div>
                        <Form.Group className="mb-3">
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Write description here..."
                            />
                        </Form.Group>
                    </div>
                    <div className="feedback-btns">
                        <button type="button" class="cmn-red-btn mb-0 w-100">
                            Submit Feedback
                        </button>
                        <button type="button" class="skip w-100">
                            Skip
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OrderFeedack