import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useGetMyProfile from '../Hook/useGetUserProfile';
import { ImagePath } from '../utilis/ImageUrl';
import moment from 'moment';
import { editProfile, getUserProfile } from '../Redux/Action/AuthAction';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

const EditUserProfile = ({ show, handleClose }) => {
    const [file, setFile] = useState(null);
    const userProfileData = useGetMyProfile();
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            dob: '',
        },
        validationSchema: Yup.object({
            first_name: Yup.string().required('First name is required'),
            last_name: Yup.string().required('Last name is required'),
            dob: Yup.date()
                .max(new Date(), "Date cannot be in the future")
                .required("Date of Birth is required"),
        }),
        onSubmit: async (values) => {
            console.log(values, "values");
            if (!file) {
                return toast.warn("Please add an Image");
            }
            let formData = new FormData();
            if (values?.profile_pic) {
                formData.append("profile_pic", values?.profile_pic);
            }
            formData.append("first_name", values?.first_name);
            formData.append("last_name", values.last_name);
            formData.append("dob", values.dob);
            await dispatch(editProfile(formData)).then((res) => {
                console.log("editProfile", res);
                if (res?.payload?.status === 200) {
                    dispatch(getUserProfile()).then((response) => {
                        if (response) {
                            toast.success(res?.payload?.message);
                            handleClose();
                        }
                    })
                } else {
                    toast.error(res?.payload?.message);
                    handleClose();
                }
            })
        },
    });

    useEffect(() => {
        if (userProfileData) {
            formik.setValues({
                first_name: userProfileData.first_name || '',
                last_name: userProfileData.last_name || '',
                dob: moment(userProfileData.dob).format('YYYY-MM-DD') || '',
            });
            setFile(ImagePath(userProfileData.profile_pic));
        }
    }, [userProfileData]);

    const handleChange = (e) => {
        const file = e.target.files[0];
        setFile(URL.createObjectURL(file));
        formik.setFieldValue('profile_pic', file);
    };

    return (
        <>
            <Modal
                className="cmn-modal"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h2 className="heading-25 mb-4 px-4">Edit Profile</h2>
                    <form onSubmit={formik.handleSubmit} className="cmn-form-fields px-4">
                        <div className="image-upload-input">
                            <div className="image-upload-view">
                                <img className="view-img" src={file || ImagePath(userProfileData?.profile_pic)} alt="Profile" />
                                <img src={require("../Assets/images/userico.svg").default} alt="User Icon" />
                            </div>
                            <div className="image-upload-camera">
                                <input type="file" onChange={handleChange} />
                                <img src={require("../Assets/images/camera.svg").default} alt="Camera Icon" />
                            </div>
                        </div>

                        <Form.Group className="mb-3 form-inner">
                            <img src={require("../Assets/images/user.svg").default} alt="User Icon" />
                            <Form.Control
                                type="text"
                                placeholder="First Name"
                                {...formik.getFieldProps('first_name')}
                                isInvalid={formik.touched.first_name && formik.errors.first_name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.first_name}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3 form-inner">
                            <img src={require("../Assets/images/user.svg").default} alt="User Icon" />
                            <Form.Control
                                type="text"
                                placeholder="Last Name"
                                {...formik.getFieldProps('last_name')}
                                isInvalid={formik.touched.last_name && formik.errors.last_name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.last_name}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3 form-inner">
                            <img src={require("../Assets/images/date.svg").default} alt="Date Icon" />
                            <Form.Control
                                type="date"
                                {...formik.getFieldProps('dob')}
                                max={new Date().toISOString().split("T")[0]}
                                isInvalid={formik.touched.dob && formik.errors.dob}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.dob}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <button type="submit" className="cmn-red-btn mb-0 w-100">
                            Save
                        </button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EditUserProfile;
