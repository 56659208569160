import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userDeleteAction } from "../Redux/Action/AuthAction";
import { toast } from "react-toastify";
import { deleteAddressAction, getListAddressAction } from "../Redux/Action/AddresAction";

const AddressDeleteModal = ({
    isModalOpen,
    setIsModalOpen,
    idVal
}) => {
    const dispatch = useDispatch();

    const handleDelete = async () => {
        await dispatch(deleteAddressAction({ id: idVal })).then((res) => {
            console.log("delete", res);
            if (res?.payload?.status === 200) {
                dispatch(getListAddressAction())
                toast.success(res?.payload?.message);
                handleClose()
            } else {
                toast.error(res?.payload?.message);
            }
        })
    }

    const handleClose = () => {
        setIsModalOpen(false)
    }


    return (
        <Modal
            show={isModalOpen}
            onHide={handleClose}
            className="comn-modal-set"
        >
            <Modal.Header className="border-none" closeButton>
                <Modal.Title className="text-center modal-title ">
                    Address Delete
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className="text-center suspend-text">
                    {" "}
                    Are you sure you want to Delete Address ?
                </p>
            </Modal.Body>
            <Modal.Footer className="border-none justify-content-center modal-footer">
                <Button
                    onClick={() => handleDelete()}
                    variant="danger"
                    className="modal-close-btn "
                >
                    Yes
                </Button>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                    className="modal-f-btn "
                >
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddressDeleteModal;
