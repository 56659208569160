import React, { useEffect, useState } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { toast } from 'react-toastify';

const containerStyle = {
  position: "relative", // Ensures it takes relative positioning to its parent
  width: "330px", // Set the width to a fixed size
  height: "500px", // Set the height to a fixed size
  borderRadius: "20px",
};

const defaultCenter = {
  lat: 30.7333, // Latitude for Chandigarh
  lng: 76.7794, // Longitude for Chandigarh
};

const MyComponent = (props) => {
  const { address, setAddress, type, editAddressData } = props
  console.log("props", props)
  const [markerPosition, setMarkerPosition] = useState(null);
  const [mapCenter, setMapCenter] = useState(defaultCenter);

  const onMapClick = (mapProps, map, clickEvent) => {
    const newLocation = {
      lat: clickEvent?.latLng?.lat(),
      lng: clickEvent?.latLng?.lng(),
    };
    setMarkerPosition(newLocation);
    setMapCenter(newLocation);

    fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${newLocation?.lat},${newLocation?.lng}&key=AIzaSyBY7WePV2Eg7cigEfbsFvjs1GzEHx6GXVA`)
      .then(response => response.json())
      .then(data => {
        if (data.status === 'OK' && data.results.length > 0) {
          let selectedResult = data.results[0];

          for (let i = 0; i < data.results.length; i++) {
            const result = data.results[i];
            if (result.types.includes('street_address') || result.types.includes('premise') || result.types.includes('establishment')) {
              selectedResult = result;
              break;
            }
          }
          const formattedAddress = selectedResult.formatted_address;
          const placeId = selectedResult.place_id;

          setAddress({
            label: formattedAddress,
            lat: newLocation?.lat,
            lng: newLocation?.lng,
            value: {
              description: formattedAddress,
              place_id: placeId,
            },
          });

        } else {
          console.error('Geocode was not successful for the following reason: ' + data.status);
        }
      })
      .catch(error => {
        console.error('Error occurred during reverse geocoding: ', error);
      });
  };

  const handlePlaceSelect = (place) => {
    console.log("place", place)
    if (!place) {
      // Clear the address state if the place is empty
      setAddress(null);
      return;
    }

    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ placeId: place?.value?.place_id }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const location = results[0]?.geometry?.location;
        const newLocation = {
          lat: location?.lat(),
          lng: location?.lng(),
        };
        setMarkerPosition(newLocation);
        setMapCenter(newLocation);
        setAddress({
          label: place.label,
          lat: newLocation?.lat,
          lng: newLocation?.lng,
          value: {
            description: place.value.description,
            place_id: place.value.place_id,
          },
        });

      } else {
        console.error('Geocode was not successful for the following reason: ' + status);
      }
    });
  };

  useEffect(() => {
    if (editAddressData) {
      setAddress({ ...address, ...editAddressData })
    }
  }, [editAddressData])

  console.log("mapCenter", mapCenter)


  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMapCenter({ lat: latitude, lng: longitude });
          setMarkerPosition({ lat: latitude, lng: longitude })
          if (type != "emptyField") {
            let full_address = fetchFullAddress(latitude, longitude)
            console.log("full_addressfull_address", full_address)
          }
        },
        () => {
          setMapCenter({ lat: 30.7316253, lng: 76.7054785 });
        }
      );
    } else {
      setMapCenter({ lat: 30.7316253, lng: 76.7054785 });
    }
  }, []);


  const fetchFullAddress = async (lat, lng) => {
    try {
      let apiKey = "AIzaSyBY7WePV2Eg7cigEfbsFvjs1GzEHx6GXVA"
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
      const response = await fetch(url);
      const data = await response.json();
      if (data.results.length > 0) {
        const addressComponents = data.results[0];
        setAddress({
          label: addressComponents?.formatted_address,
          lat: addressComponents?.geometry?.location?.lat,
          lng: addressComponents?.geometry?.location?.lng,
          value: {
            description: addressComponents?.formatted_address,
            place_id: addressComponents?.place_id,
          },
        });
        return addressComponents ? addressComponents : "Others"
      }
    } catch (err) {
      toast.error("Error fetching geocoding data");
    }
  };

  console.log("googleMap address1-------", address)

  return (
    <>
      <GooglePlacesAutocomplete
        apiKey="AIzaSyBY7WePV2Eg7cigEfbsFvjs1GzEHx6GXVA"
        selectProps={{
          value: address,
          onChange: (place) => handlePlaceSelect(place),
          isClearable: true,
          placeholder: 'Search for a location...',
        }}
      />

      <div className='map-set-pop' style={{ width: "100%", height: "100%" }}>
        <Map
          google={props.google}
          zoom={18}
          style={containerStyle}
          initialCenter={mapCenter}
          center={mapCenter}
          onClick={onMapClick}

        >
          <Marker position={markerPosition} />
        </Map>
      </div>


    </>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBY7WePV2Eg7cigEfbsFvjs1GzEHx6GXVA',
})(MyComponent);
