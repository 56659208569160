import { useEffect } from 'react';
// import { jwtDecode } from "jwt-decode";

const useAuthToken = () => {
  const token = localStorage.getItem('token');

  // useEffect(() => {
  //   if (!token) {
  //     sessionStorage.clear();
  //     localStorage.clear();
  //     window.location.href = '/';
  //   }
  // }, [token]);

//   const decodedToken = token ? jwtDecode(token) : null;

  return  token;
};

export default useAuthToken
