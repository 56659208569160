import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { addCartAction, municipalityAction } from '../Redux/Action/CartAction'
import { toast } from 'react-toastify'

const OutOfStockModal = ({ showTwo, setShowTwo, formDataState, setShowModalOpen, productId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleContinue = async () => {
        await dispatch(municipalityAction({ id: productId })).then((res) => {
            if (res?.payload?.status === 200) {
                if (res?.payload?.data?.outside_municipality == true) {
                    setShowModalOpen(true)
                    setShowTwo(false)
                } else {
                    dispatch(addCartAction({ ...formDataState, product_id: productId })).then((res) => {
                        console.log("cart res", res)
                        if (res?.payload?.status === 200) {
                            toast.success("Added to Cart Successfully")
                            setShowTwo(false)
                            navigate(`/cart`)
                        } else {
                            setShowModalOpen(false)
                            toast.error(res?.payload?.message)
                        }
                    })
                    setShowTwo(false)
                }
            }
        })
    }

    return (
        <>
            <Modal
                className="cmn-modal"
                show={showTwo}
                onHide={() => setShowTwo(false)}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="modal-small-section">
                    <div className="cmn-modal-sec">
                        <p>We can't add this product to cart as this order is out of stock </p>
                        <button className="second-cmn-button" onClick={handleContinue}>
                            Continue
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OutOfStockModal