import React from 'react';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { editProfile, getUserProfile } from '../Redux/Action/AuthAction';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
// import 'react-google-places-autocomplete/dist/assets/index.css';

const GoogleAutoComplete = ({ intialState, setIntialState, handleClose, type }) => {
    console.log("GoogleAutoComplete intialState", intialState)
    const dispatch = useDispatch();

    const handleSelect = async (place) => {
        try {
            if (!place) {
                // Clear the address state if the place is empty
                setIntialState(null);
                return;
            }

            const { description, place_id } = place.value;
            const results = await geocodeByAddress(description);
            const { lat, lng } = await getLatLng(results[0]);
            // onSelect({ address: description, lat, lng });
            setIntialState({
                label: place.label,
                lat: lat,
                lng: lng,
                value: {
                    description: description,
                    place_id: place_id
                }
            })
            if (type == "setheaderlocation") {
                let payload = {
                    label: place.label,
                    lat: lat,
                    lng: lng,
                    value: {
                        description: description,
                        place_id: place_id
                    }
                }
                handleSubmit(payload)
            }
        } catch (error) {
            console.error('Error fetching address details:', error);
        }
    };

    const handleSubmit = async (data) => {
        let formData = new FormData();
        formData.append("address", data.label);
        formData.append("place_id", data.value.place_id);
        formData.append("lat", data.lat);
        formData.append("lng", data.lng);
        await dispatch(editProfile(formData)).then((res) => {
            if (res?.payload?.status === 200) {
                dispatch(getUserProfile())
            } else {
                toast.error(res?.payload?.message);
            }
            handleClose()
            setIntialState({
                label: '',
                lat: '',
                lng: '',
                value: {
                    description: '',
                    place_id: '',
                },
            })
        });
    }

    return (
        <div className="location-search">
            <GooglePlacesAutocomplete
                apiKey={"AIzaSyBY7WePV2Eg7cigEfbsFvjs1GzEHx6GXVA"}
                selectProps={{
                    value: intialState,
                    onChange: handleSelect,
                    placeholder: 'Search for a location...',
                    isClearable: intialState?.label ? true : false,
                }}

            />
        </div>
    );
};

export default GoogleAutoComplete;
