import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer>
      <Container>
        <Row>
          <Col lg={4}>
            <div className="footer-left">
              <img src={require("../Assets/images/logo.png")} />
              <p>
                Every pastry, bread, and treat here is baked with love, a
                tribute to the rich traditions of French bakeries. From the
                first knead to the golden-brown bake.
              </p>
              <div className="foot-images">
                <Link to="#">
                  <img src={require("../Assets/images/facebook.svg").default} />
                </Link>
                <Link to="#">
                  <img
                    src={require("../Assets/images/instagram.svg").default}
                  />
                </Link>
                <Link to="#">
                  <img src={require("../Assets/images/x-icon.svg").default} />
                </Link>
                <Link to="#">
                  <img src={require("../Assets/images/linkdin.svg").default} />
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={8}>
            <div className="footer-left">
              <Row>
                <Col lg={3}>
                  <div className="footer-menu text-left">
                    <div className="fot-title">
                      <h3>Company</h3>
                    </div>
                    <ul>
                      <li>
                        <Link to="#">About Us</Link>
                      </li>
                      <li>
                        <Link to="#">Blog</Link>
                      </li>
                      <li>
                        <Link to="#">Careers</Link>
                      </li>
                      <li>
                        <Link to="#">For Bakers</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="footer-menu text-left">
                    <div className="fot-title">
                      <h3>Quick Links</h3>
                    </div>
                    <ul>
                      <li>
                        <Link to="#">Home</Link>
                      </li>
                      <li>
                        <Link to="#">FAQs</Link>
                      </li>
                      <li>
                        <Link to="#">Cookie Policy</Link>
                      </li>
                      <li>
                        <Link to="#">Terms of Service</Link>
                      </li>
                      <li>
                        <Link to="#">Privacy & Cokie Policy</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="footer-menu text-left">
                    <div className="fot-title">
                      <h3>Need Help?</h3>
                    </div>
                    <ul>
                      <li>
                        <Link to="#">Contact Support</Link>
                      </li>
                      <li>
                        <Link to="#">Help Center</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="footer-menu text-left">
                    <div className="fot-title">
                      <h3>Contact Us</h3>
                    </div>
                    <ul>
                      <li>
                        <Link to="#">
                          <span>
                            <img
                              src={
                                require("../Assets/images/email-icon.svg")
                                  .default
                              }
                            />
                          </span>
                          info@companyname.com
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>
                            <img
                              src={
                                require("../Assets/images/phone-icon.svg")
                                  .default
                              }
                            />
                          </span>
                          (123) 456-789
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span>
                            <img
                              src={
                                require("../Assets/images/location-icon.svg")
                                  .default
                              }
                            />
                          </span>
                          1234 Street Name, State, Zip Code
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <div className="bottom-sec">
          <div className="rights-res">
            <p>
              <span></span> © 2024 Milcake. All rights reserved
            </p>
          </div>
          <div className="app-store">
            <Link to="#">
              <img src={require("../Assets/images/google-play.svg").default} />
            </Link>
            <Link to="#">
              <img src={require("../Assets/images/app-store.svg").default} />
            </Link>
          </div>
        </div>
      </Container>
    </footer>
  );
}
