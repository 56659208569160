import React, { useEffect } from 'react'
import Layout from '../Components/Layout'
import AllPages from '../CommonComponent/AllPages'
import { Container } from 'react-bootstrap';
import { useQuery } from '../utilis/commonFunction';

const ViewAllPages = () => {
    const query = useQuery();
    const title = query.get("title");
    console.log("title", title)

    return (
        <>
            <Layout>
                <Container>
                    <AllPages title={title}/>
                </Container>
            </Layout>
        </>

    )
}

export default ViewAllPages