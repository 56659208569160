import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile } from '../Redux/Action/AuthAction';

const useGetMyProfile = () => {
    const dispatch = useDispatch();
    const { userDetails } = useSelector((state) => state.authData)

    // useEffect(() => {
    //     dispatch(getUserProfile())
    // }, [])

    return userDetails;
};

export default useGetMyProfile
