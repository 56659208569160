import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Decryptedid } from "../utilis/BcryptEcryptId";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { resetpasswordAction } from "../Redux/Action/AuthAction";
import { toast } from "react-toastify";

export default function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);
  const { id } = useParams();
  const userId = id && Decryptedid(atob(id));
  console.log("userId", userId);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is Required"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values", values);
      let payload = {
        _id: userId,
        password: values?.password,
      };
      setIsLoader(true);
      dispatch(resetpasswordAction(payload)).then((res) => {
        console.log("res", res);
        if (res?.payload?.status === 200) {
          toast.success(res?.payload?.message);
          navigate(`/password-changed`);
        } else {
          toast.error(res?.payload?.error_description);
        }
        setIsLoader(false);
      });
    },
  });

  return (
    <div>
      <Container fluid>
        <div className="main-form">
          <Row>
            <Col
              lg={6}
              className="px-0 d-flex align-items-center justify-content-center"
            >
              <div className="cmn-form">
                <h2 className="heading-30">Reset Password</h2>
                <p className="sub-16">
                  Your new password must be unique from those previously used.
                </p>

                <form onSubmit={formik.handleSubmit}>
                  <div className="cmn-form-fields">
                    <Form.Group className="mb-3 form-inner">
                      <img
                        src={require("../Assets/images/email.svg").default}
                        alt="password"
                      />
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="New Password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <button
                        type="button"
                        className="pass-show"
                        onClick={() => setShowPassword((prev) => !prev)}
                      >
                        {showPassword ? (
                          <img
                            src={
                              require("../Assets/images/hide_password.svg")
                                .default
                            }
                            alt="hide password"
                          />
                        ) : (
                          <img
                            src={require("../Assets/images/eye.svg").default}
                            alt="show password"
                          />
                        )}
                      </button>
                      {formik.touched.password && formik.errors.password ? (
                        <div className="text-danger">
                          {formik.errors.password}
                        </div>
                      ) : null}
                    </Form.Group>

                    <Form.Group className="mb-3 form-inner">
                      <img
                        src={require("../Assets/images/email.svg").default}
                        alt="confirm password"
                      />
                      <Form.Control
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <button
                        type="button"
                        className="pass-show"
                        onClick={() => setShowConfirmPassword((prev) => !prev)}
                      >
                        {showConfirmPassword ? (
                          <img
                            src={
                              require("../Assets/images/hide_password.svg")
                                .default
                            }
                            alt="hide password"
                          />
                        ) : (
                          <img
                            src={require("../Assets/images/eye.svg").default}
                            alt="toggle visibility"
                          />
                        )}
                      </button>
                      {formik.touched.confirmPassword &&
                        formik.errors.confirmPassword ? (
                        <div className="text-danger">
                          {formik.errors.confirmPassword}
                        </div>
                      ) : null}
                    </Form.Group>
                  </div>
                  <button type="submit" className="cmn-red-btn mb-0" disabled={isLoader ? true : false}>
                    {isLoader &&
                      <>
                        <div class="spinner-border spinner-border-sm" role="status" >
                        </div>{" "}
                      </>
                    }
                    Reset Password
                  </button>
                </form>
              </div>
            </Col>
            <Col lg={6} className="px-0">
              <div className="left-img">
                <img
                  src={require("../Assets/images/leftimg.jpeg")}
                  alt="left"
                />
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      {/* Uncomment and use as needed */}
      {/* <img className="cmn-first-img" src={require("../Assets/images/donut.png")} alt="donut" />
      <img className="cmn-second-img" src={require("../Assets/images/cupcake.png")} alt="cupcake" />
      <img className="cmn-third-img" src={require("../Assets/images/cake.png")} alt="cake" /> */}
    </div>
  );
}
