import React, { useEffect, useState } from "react";
import { Col, Container, Dropdown, Modal, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../Components/Layout";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useDispatch, useSelector } from "react-redux";
import { getListOrderAction } from "../Redux/Action/OrderAction";
import moment from "moment";
export default function OrderListing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderList } = useSelector((state) => state.order)
  const [activeTabIndex, setActiveTabIndex] = useState(1);

  console.log("orderList", orderList)

  useEffect(() => {
    if (activeTabIndex == 0 || activeTabIndex == 1) {
      dispatch(getListOrderAction({ status: activeTabIndex === 1 ? `1` : `2` }))
    }
  }, [activeTabIndex, dispatch])


  const handleTabSelect = (index) => {
    setActiveTabIndex(index);
  };

  return (
    <Layout>
      <Container>
        <Link to="#" className="mt-4 d-block" onClick={() => navigate(-1)}>
          <img src={require("../Assets/images/backimg.svg").default} />
        </Link>
        <div className="cart-header">
          <h2>Orders </h2>
          <Tabs selectedIndex={activeTabIndex} onSelect={handleTabSelect}>
            <div className="order-tab">
              <div className="filter-dropdowns">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img
                      src={require("../Assets/images/filters.svg").default}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <div className="filters">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault1"
                        >
                          Few Days Back
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault1"
                        >
                          One Week ago
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault1"
                        >
                          On Month ago
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault1"
                        >
                          Three Months ago
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault1"
                        >
                          Six Months ago
                        </label>
                      </div>
                      <button type="button" className="cmn-red-btn">
                        Apply
                      </button>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <TabList className="tabs-switch">
                <Tab > Completed</Tab>
                <Tab> In Progress</Tab>
              </TabList>
            </div>
            <TabPanel>
              <div className="cart-card">
                <Row>
                  {
                    Array.isArray(orderList?.data) && orderList?.data?.length > 0 ?
                      orderList?.data?.map((res) => {
                        return <>
                          <Col lg={4} className="mb-4" key={res?._id}>
                            <div className="orders-card">
                              <div className="orderscard-top">
                                <Row>
                                  <Col lg={3}>
                                    <img
                                      src={
                                        require("../Assets/images/orders.svg").default
                                      }
                                    />
                                  </Col>
                                  <Col lg={6}>
                                    <h3>Order Id {res?.order_no || 'N/A'}</h3>
                                    <p>Placed on {moment(res?.selected_date).format('DD-MMM-YYYY')}</p>
                                    <div className="d-flex">
                                      <h4>Item: 10 </h4>
                                      <h4>Price: $600</h4>
                                    </div>
                                  </Col>
                                  <Col lg={3} className="ps-0">
                                    <h5>Give Rating</h5>
                                    <button className="mt-4 pt-1">
                                      <svg
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M0 13.8873C0.0153539 13.8471 0.0319873 13.8073 0.0459864 13.7667C0.178903 13.3794 0.524215 13.1271 0.9329 13.127C5.30914 13.1264 9.68537 13.1263 14.0616 13.1271C14.5283 13.1272 14.9175 13.4638 14.989 13.9212C15.0588 14.3678 14.7902 14.8043 14.3543 14.9535C14.3137 14.9674 14.2742 14.9844 14.234 15C9.74318 15 5.25231 15 0.761448 15C0.647573 14.9487 0.527753 14.9075 0.421028 14.8441C0.191246 14.7077 0.0797048 14.4827 0 14.2387C0 14.1215 0 14.0044 0 13.8873Z"
                                          fill="#A72A2F"
                                        />
                                        <path
                                          d="M6.56108 8.15928C6.56108 8.06521 6.56108 8.00688 6.56108 7.94856C6.56108 5.61614 6.56033 3.28372 6.56183 0.951298C6.56206 0.55777 6.74608 0.266895 7.09847 0.0989177C7.44619 -0.0668021 7.78525 -0.0220231 8.08676 0.216171C8.32655 0.405597 8.43561 0.662606 8.43539 0.970866C8.43365 3.30328 8.43448 5.6357 8.43448 7.96812C8.43448 8.0208 8.43448 8.07349 8.43448 8.15574C8.49055 8.10457 8.52901 8.07213 8.56454 8.03668C9.09289 7.50912 9.61696 6.97719 10.1501 6.45437C10.6767 5.93794 11.5337 6.16545 11.7229 6.87017C11.8129 7.20583 11.7389 7.51559 11.4937 7.76237C10.3825 8.88041 9.26894 9.99605 8.14923 11.1054C7.78021 11.471 7.21332 11.4689 6.844 11.1027C5.72821 9.99627 4.61716 8.885 3.50987 7.76997C3.14123 7.39872 3.14936 6.81569 3.50919 6.45249C3.87407 6.08417 4.46121 6.07612 4.83806 6.44632C5.3707 6.96959 5.89537 7.50084 6.42365 8.02848C6.46038 8.06528 6.49891 8.10028 6.56108 8.15928Z"
                                          fill="#A72A2F"
                                        />
                                      </svg>
                                      Invoice
                                    </button>
                                  </Col>
                                </Row>
                              </div>
                              <div className="orderscard-bottom">
                                <Row>
                                  <Col lg={8}>
                                    <h4>Delivered on {moment(res?.selected_date).format('DD-MMM-YYY')}</h4>
                                  </Col>
                                  <Col lg={4} className="d-flex justify-content-end">
                                    <Link to={`/single-order/${res?._id}`}>
                                      Details{" "}
                                      <img
                                        src={
                                          require("../Assets/images/arrow-right.svg")
                                            .default
                                        }
                                      />
                                    </Link>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col>
                        </>
                      })
                      :
                      <p>No Data Found</p>
                  }


                </Row>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="cart-card">
                <Row>
                  {
                    Array.isArray(orderList?.data) && orderList?.data?.length > 0 ?
                      orderList?.data?.map((res) => {
                        return <>
                          <Col lg={4} className="mb-4" key={res?._id}>
                            <div className="orders-card">
                              <div className="orderscard-top">
                                <Row>
                                  <Col lg={3}>
                                    <img
                                      src={
                                        require("../Assets/images/orders.svg").default
                                      }
                                    />
                                  </Col>
                                  <Col lg={6}>
                                    <h3>Order Id {res?.order_no}</h3>
                                    <p>Placed on {moment(res?.createdAt).format('DD-MMM-YYYY')}</p>
                                    <div className="d-flex">
                                      <h4>Item: {res?.items_quantity || '0'} </h4>
                                      <h4>Price: ${res?.items_total_price || '0'}</h4>
                                    </div>
                                  </Col>
                                  <Col lg={3} className="">
                                    <h5>Delivery</h5>
                                  </Col>
                                </Row>
                              </div>
                              <div className="progress-bottom">
                                <ul>
                                  <li>
                                    <h3>
                                      {" "}
                                      <svg
                                        width="8"
                                        height="8"
                                        viewBox="0 0 8 8"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <circle fill="#A72A2F" cx="4" cy="4" r="4" />
                                      </svg>
                                      Order Placed
                                    </h3>
                                    <p>
                                      {res?.createdAt ? moment(res?.createdAt).format("DD-MMM-YYYY") : "Pending"}
                                    </p>
                                  </li>

                                  {
                                    res?.order_status === 5 ?
                                      <li>
                                        <h3>
                                          <svg
                                            width="8"
                                            height="8"
                                            viewBox="0 0 8 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <circle cx="4" cy="4" r="4" fill="black" />
                                          </svg>
                                          Order Rejected
                                        </h3>
                                        <p>
                                          Rejected
                                        </p>
                                      </li>
                                      : <>
                                        <li>
                                          <h3>
                                            <svg
                                              width="8"
                                              height="8"
                                              viewBox="0 0 8 8"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <circle cx="4" cy="4" r="4" fill={`${res?.confirmed_at ? `#A72A2F` : `black`}`} />
                                            </svg>
                                            Order Confirmed
                                          </h3>
                                          <p>
                                            {res?.confirmed_at ? moment(res?.confirmed_at).format(
                                              "DD-MMM-YYYY") : "Pending"}
                                          </p>
                                        </li>
                                        <li>
                                          <h3>
                                            <svg
                                              width="8"
                                              height="8"
                                              viewBox="0 0 8 8"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <circle cx="4" cy="4" r="4" fill={`${res?.picked_at ? `#A72A2F` : `black`}`} />
                                            </svg>
                                            Out for Delivery
                                          </h3>
                                          <p>
                                            {res?.picked_at ? moment(res?.picked_at).format(
                                              "DD-MMM-YYYY") : "Pending"}
                                          </p>
                                        </li>
                                        <li>
                                          <h3>
                                            <svg
                                              width="8"
                                              height="8"
                                              viewBox="0 0 8 8"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <circle cx="4" cy="4" r="4" fill={`${res?.delivered_at ? `#A72A2F` : `black`}`} />
                                            </svg>
                                            Order Delivered
                                          </h3>
                                          <p>
                                            {res?.delivered_at ? moment(res?.delivered_at).format(
                                              "DD-MMM-YYYY") : "Pending"}
                                          </p>
                                        </li>
                                      </>

                                  }

                                </ul>
                                <Link to={`/single-order/${res?._id}`}>
                                  Details{" "}
                                  <img
                                    src={
                                      require("../Assets/images/arrow-right.svg")
                                        .default
                                    }
                                  />
                                </Link>
                              </div>
                            </div>
                          </Col>
                        </>
                      })
                      : <p>No Data Found</p>
                  }
                </Row>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </Container>
    </Layout>
  );
}
