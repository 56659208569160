import React from 'react';

const ProductDetailsFillingCard = ({ data, formik }) => {
  return (
    <>
      <div className="cake-box">
        <h3>Choose Your Filling</h3>
        {data?.product_fillings?.length > 1 && <p>Select any 1 option</p>}
        {
          Array.isArray(data?.product_fillings) && data?.product_fillings?.length > 0 ?
            data?.product_fillings.map((res) => {
              return <>
                <div className="cake-size-inner mb-0" key={res?._id}>
                  <h4>{res?.name || 'N/A'}</h4>
                  <div className="d-flex">
                    <span>${res?.price || '0'}</span>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="filling_id"
                        // id={`filling-${res?._id}`}
                        value={res?._id}  
                        onChange={formik.handleChange}
                        checked={formik.values.filling_id === res?._id}
                      />
                    </div>
                  </div>
                </div>
              </>
            })
            : <p>No Data Available</p>
        }

      </div>

      <div className="cake-detail-input">
        <textarea
          className="form-control"
          id="request"
          rows="1"
          placeholder="Add a Request"
          name="request"
          value={formik.values.request}
          onChange={formik.handleChange}
        />
      </div>
    </>
  );
};

export default ProductDetailsFillingCard;
