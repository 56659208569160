import moment from 'moment'
import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const OrderPlacedModal = ({ showSuccess, setShowSuccess }) => {
    const { orderDetails } = useSelector((state) => state.order)
    console.log("OrderDetails", orderDetails)
    return (
        <>
            <Modal
                className="cmn-modal"
                show={showSuccess}
                // onHide={() => setShowSuccess(false)}
                backdrop="static"
                keyboard={false}
                centered
            >
                {/* <Modal.Header closeButton></Modal.Header> */}
                <Modal.Body>
                    <h2 className="heading-25 mb-4 ">Order Success</h2>
                    <div className="order-success">
                        <img src={require("../Assets/images/ordersuccess.svg").default} />
                        <h2>Order Placed</h2>
                        <p className="text-center">
                            You will receive an order
                            <br /> confirmation email
                        </p>
                        <div className="orders-card">
                            <div className="orderscard-top">
                                <Row>
                                    <Col lg={3}>
                                        <img src={require("../Assets/images/orders.svg").default} />
                                    </Col>
                                    <Col lg={9}>
                                        <h3>Order Id {orderDetails?.order_no}</h3>
                                        <p>Placed on {moment(orderDetails?.createdAt).format('DD-MMM-YYYY')}</p>
                                        <div className="d-flex">
                                            <h4>Item: {orderDetails?.items_quantity || '0'} </h4>
                                            <h4>Price: ${orderDetails?.to_pay || '0'}</h4>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <Link to={`/single-order/${orderDetails?._id}`} className='no-underline'>
                            <button type="button" class="cmn-red-btn mb-0 w-100">
                                Track Order
                            </button>
                        </Link>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OrderPlacedModal