import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from '../Components/CheckoutForm';

const stripePromise = loadStripe('pk_test_51PnGht104yRhJCICXqugdYya2AxwK4byLKfCzauTl4jSDNtOl0wb6dj5FC5BmOdDYtMkkf9ugGeRcNvFQWmRV0Fd00OmxzgXya');

const PaymentModal = ({ showPayment, setShowPayment, clientSecret, intentId, setShowSuccess }) => {
    console.log("clientSecret", clientSecret)
    const [dropToogle, setDropToogle] = useState({
        visaCard: false,
        banContact: false,
        paypal: false
    });

    const handleCloseModal = () => {
        setShowPayment(false)
        setDropToogle({
            visaCard: false,
            banContact: false,
            paypal: false
        })
    }

    return (
        <>
            <Modal
                className="cmn-modal"
                show={showPayment}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h2 className="heading-25 mb-4 px-4">Choose Payment Method</h2>
                    <div className={`billing-box ${dropToogle.visaCard && `active`}`} onClick={() => setDropToogle({ ...dropToogle.visaCard, visaCard: !dropToogle.visaCard })}>
                        <div>
                            <img src={require("../Assets/images/visa.png")} />
                            <p>Add Card</p>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12" fill="none">
                            <path d="M0.79013 12C0.490866 11.8811 0.309622 11.5939 0.115733 11.3314C-0.0423291 11.1184 -0.0275767 10.7717 0.124163 10.5513C0.15999 10.4993 0.200032 10.4523 0.240075 10.4052C1.4582 8.97139 2.67844 7.54004 3.89657 6.10869C3.92397 6.0765 3.9598 6.05421 3.99773 6.02201C3.95137 5.96506 3.92608 5.93286 3.89868 5.90067C2.67001 4.45446 1.44134 3.01321 0.212677 1.567C-0.00650177 1.31194 -0.071834 0.992483 0.0904428 0.734939C0.240075 0.497207 0.429749 0.284238 0.62996 0.103462C0.817527 -0.064932 1.07253 -0.0154044 1.25799 0.162895C1.28539 0.190135 1.31279 0.219852 1.33808 0.249568C2.82175 1.99294 4.30753 3.73879 5.7912 5.48216C6.01249 5.74466 6.06096 6.04925 5.92187 6.32909C5.88815 6.39842 5.83968 6.46033 5.7912 6.51977C4.30753 8.26562 2.82386 10.0115 1.33597 11.7499C1.24535 11.8564 1.1189 11.9183 1.00931 12C0.935547 12 0.861784 12 0.79013 12Z" fill="#8391A1" />
                        </svg>
                    </div>
                    {
                        dropToogle.visaCard == true &&
                        <>
                            <Elements stripe={stripePromise} options={{ clientSecret }}>
                                <CheckoutForm clientSecret={clientSecret} intentId={intentId} setShowPayment={setShowPayment} setShowSuccess={setShowSuccess} />
                            </Elements>
                        </>
                    }
                    {/* BanContact Card */}
                    <div className={`billing-box ${dropToogle.banContact && `active`}`} onClick={() => setDropToogle({ ...dropToogle.banContact, banContact: !dropToogle.banContact })}>
                        <div>
                            <img src={require("../Assets/images/bancontact.png")} />
                            <p>BanContact</p>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12" fill="none">
                            <path d="M0.79013 12C0.490866 11.8811 0.309622 11.5939 0.115733 11.3314C-0.0423291 11.1184 -0.0275767 10.7717 0.124163 10.5513C0.15999 10.4993 0.200032 10.4523 0.240075 10.4052C1.4582 8.97139 2.67844 7.54004 3.89657 6.10869C3.92397 6.0765 3.9598 6.05421 3.99773 6.02201C3.95137 5.96506 3.92608 5.93286 3.89868 5.90067C2.67001 4.45446 1.44134 3.01321 0.212677 1.567C-0.00650177 1.31194 -0.071834 0.992483 0.0904428 0.734939C0.240075 0.497207 0.429749 0.284238 0.62996 0.103462C0.817527 -0.064932 1.07253 -0.0154044 1.25799 0.162895C1.28539 0.190135 1.31279 0.219852 1.33808 0.249568C2.82175 1.99294 4.30753 3.73879 5.7912 5.48216C6.01249 5.74466 6.06096 6.04925 5.92187 6.32909C5.88815 6.39842 5.83968 6.46033 5.7912 6.51977C4.30753 8.26562 2.82386 10.0115 1.33597 11.7499C1.24535 11.8564 1.1189 11.9183 1.00931 12C0.935547 12 0.861784 12 0.79013 12Z" fill="#8391A1" />
                        </svg>
                    </div>
                    {
                        dropToogle.banContact &&
                        <>
                          Under Development
                        </>
                    }

                    {/* Paypal Card */}
                    <div className={`billing-box ${dropToogle.paypal && `active`}`} onClick={() => setDropToogle({ ...dropToogle.paypal, paypal: !dropToogle.paypal })}>
                        <div>
                            <img src={require("../Assets/images/paypal.png")} />
                            <p>PayPal</p>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12" fill="none">
                            <path d="M0.79013 12C0.490866 11.8811 0.309622 11.5939 0.115733 11.3314C-0.0423291 11.1184 -0.0275767 10.7717 0.124163 10.5513C0.15999 10.4993 0.200032 10.4523 0.240075 10.4052C1.4582 8.97139 2.67844 7.54004 3.89657 6.10869C3.92397 6.0765 3.9598 6.05421 3.99773 6.02201C3.95137 5.96506 3.92608 5.93286 3.89868 5.90067C2.67001 4.45446 1.44134 3.01321 0.212677 1.567C-0.00650177 1.31194 -0.071834 0.992483 0.0904428 0.734939C0.240075 0.497207 0.429749 0.284238 0.62996 0.103462C0.817527 -0.064932 1.07253 -0.0154044 1.25799 0.162895C1.28539 0.190135 1.31279 0.219852 1.33808 0.249568C2.82175 1.99294 4.30753 3.73879 5.7912 5.48216C6.01249 5.74466 6.06096 6.04925 5.92187 6.32909C5.88815 6.39842 5.83968 6.46033 5.7912 6.51977C4.30753 8.26562 2.82386 10.0115 1.33597 11.7499C1.24535 11.8564 1.1189 11.9183 1.00931 12C0.935547 12 0.861784 12 0.79013 12Z" fill="#8391A1" />
                        </svg>
                    </div>

                    {
                        dropToogle.paypal &&
                        <>
                            <p>Under Development</p>
                        </>
                    }


                </Modal.Body>
            </Modal>
        </>
    )
}

export default PaymentModal