import { useLocation } from 'react-router-dom';

function useCheckRoute() {
  const location = useLocation();
  const path = location.pathname;

  if (path === '/') {
    return true;
  } else {
    return false;
  }
}

export default useCheckRoute;
