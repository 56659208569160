import axios from "axios";
const CommonApi = axios.create({
    baseURL: process.env.REACT_APP_HOST_NAME,
    headers: { token: localStorage.getItem("token"), }
});

// Add a response interceptor
CommonApi.interceptors.response.use(function (response) {
    if (response?.data?.status === 401) {
        localStorage.clear();
        window.location.href = '/';
        window.location.reload();
    } else {
        return response;
    }
}, function (error) {
    return Promise.reject(error);
});


export default CommonApi;