import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { Col, Container, Row } from "react-bootstrap";
import backgroundimage from "../Assets/images/singleview.png";
import { Rating } from "react-simple-star-rating";
import { Link, useParams } from "react-router-dom";
import BrowseCategoryHome from "../CommonComponent/BrowseCategoryHome";
import { useDispatch, useSelector } from "react-redux";
import {
  productListAction,
  shopDetailsAction,
  shopLikeAction,
} from "../Redux/Action/HomeAction";
import { ImagePath } from "../utilis/ImageUrl";
import ShopStatus from "../CommonComponent/ShopTimeStatus";
import { CategoryName } from "../utilis/enums";
import ProductCard from "../Components/ProductCard";
import ReactReadMoreReadLess from "react-read-more-read-less";

export default function SingleRestaurant() {
  const [categoryId, setCategoryId] = useState("");
  const dispatch = useDispatch();
  const { id } = useParams();
  const shopDetails = useSelector((state) => state?.home?.shopDetails?.data);
  const { productList } = useSelector((state) => state?.home);
  const [like, setLike] = useState(false);

  useEffect(() => {
    dispatch(shopDetailsAction({ id })).then((res) => {
      setLike(res?.payload?.data?.is_shop_liked);
    });
  }, []);

  useEffect(() => {
    let query = {
      id,
    };
    if (categoryId === CategoryName.MOSTPOPULAR) {
      query = { ...query, list_by: 2 };
    } else if (categoryId === CategoryName.FAVOURITE) {
      query = { ...query, list_by: 1 };
    } else if (categoryId === CategoryName.GOODTOGO) {
      query = { ...query, good_to_go: 1 };
    } else if (categoryId === CategoryName.ALLDELIGHT) {
      query = { ...query, category_id: "" };
    } else {
      query = { ...query, category_id: categoryId };
    }
    dispatch(productListAction(query));
  }, [categoryId, id, dispatch]);

  const shopLikeApi = async (shopId) => {
    let payload = {
      shop_id: shopId,
    };
    await dispatch(shopLikeAction(payload)).then((res) => {
      if (res?.payload?.status === 200) {
        dispatch(shopDetailsAction({ id }));
        setLike(res?.payload?.data?.is_shop_liked);
      }
    });
  };

  return (
    <Layout>
      <Container>
        <div
          className="single-banner d-flex align-items-center justify-content-center "
          style={{
            backgroundImage: `url(${shopDetails?.banner_image
              ? ImagePath(shopDetails?.banner_image)
              : backgroundimage
              })`,
          }}
        >
          <div className="d-flex justify-content-between banner-top">
            {" "}
            <Link to="/" className="back-btn">
              <img src={require("../Assets/images/back.svg").default} />
            </Link>
            <button
              className={like ? "active-heart like-btn " : "like-btn"}
              onClick={() => shopLikeApi(shopDetails?._id)}
            >
              <svg
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5445 0.75C11.5015 0.75 10.0015 3.75 10.0015 3.75C10.0015 3.75 8.50147 0.75 5.45747 0.75C2.98447 0.75 1.02647 2.82 1.00047 5.288C0.949469 10.413 5.06647 14.058 9.57947 17.12C9.7038 17.2045 9.85065 17.2496 10.001 17.2496C10.1513 17.2496 10.2981 17.2045 10.4225 17.12C14.9345 14.057 19.0525 10.413 19.0005 5.288C18.9755 2.819 17.0175 0.75 14.5445 0.75Z"
                  stroke="#A72A2F"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>

          <div className="single-content">
            <h2>{shopDetails?.name || "N/A"}</h2>
            <Rating initialValue={4} />
          </div>
        </div>
        <div className="single-description">
          <Row>
            <Col lg={4}>
              <div className="banner-left">
                <img src={require("../Assets/images/blacklocation.png")} />
                <span>{shopDetails?.address || "N/A"} </span>
                <h2>
                  <ReactReadMoreReadLess
                    charLimit={200}
                    readMoreText={"Read more "}
                    readLessText={"Read less "}
                    readMoreClassName="read-more-less--more"
                    readLessClassName="read-more-less--less"
                  >
                    {shopDetails?.description || 'N/A'}
                  </ReactReadMoreReadLess >

                </h2>
                <h2>
                  Opening Hours:{" "}
                  <ShopStatus shopSchedule={shopDetails?.opening_hours} />
                </h2>
              </div>
            </Col>
          </Row>
        </div>

        <BrowseCategoryHome
          setCategoryId={setCategoryId}
          categoryId={categoryId}
        />

        {Array.isArray(productList?.data) && productList?.data?.length > 0 ? (
          productList?.data.map((productData) => {
            return (
              <>
                <section className="pt-4" key={productData?._id}>
                  <div className="deal-cmn-heading mb-4">
                    <h2
                      className="heading-25"
                      style={{ color: "rgb(167, 42, 47)" }}
                    >
                      {productData?.name || "N/A"}
                    </h2>
                    <span></span>
                  </div>
                  <Row>
                    <ProductCard
                      productData={productData}
                      productList={productList}
                    />

                    {productData?.data?.length > 8 && (
                      <Col
                        lg={12}
                        className="d-flex justify-content-center mt-4"
                      >
                        <button className="view-button">View More</button>
                      </Col>
                    )}
                  </Row>
                </section>
              </>
            );
          })
        ) : (
          <p className="no-data-found">No Data Available </p>
        )}
      </Container>
    </Layout>
  );
}
