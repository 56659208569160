import React from 'react'
import { Link } from 'react-router-dom'
import HomeSlider from '../Components/HomeSlider'

const HomeShopList = ({ data, title }) => {
    return (
        <>
            <section className="pt-4">
                <div className="deals-sec">
                    <div className="deal-cmn-heading mb-4">
                        <h2 className="heading-25" style={{ color: "#A72A2F" }}>
                            {title}
                        </h2>
                        <span></span>
                        <Link to={`/ViewAllPages?title=${title}`} className="view-all">
                            View All
                        </Link>
                    </div>
                    <HomeSlider data={data} />
                </div>
            </section>
        </>
    )
}

export default HomeShopList