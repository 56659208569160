import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../Service/Api";
import axios from "axios";

const loginAction = createAsyncThunk(
    'login', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/login', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const signupAction = createAsyncThunk(
    'signup', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/signup', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const userOtpVerification = createAsyncThunk(
    'otp/verify', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/verify-email', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const userResendOtpVerification = createAsyncThunk(
    'resend-otp/verify', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/resend-otp', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const verifyOtpAction = createAsyncThunk(
    'verify', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/forgot-verify', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const ForgotPasswordAction = createAsyncThunk(
    'forgot-password', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/forgot-password', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const forgotOtpVerifyAction = createAsyncThunk(
    'forgot-otp-verify', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/forgot-otp-verify', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })


const resetpasswordAction = createAsyncThunk(
    'reset-password', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/reset-password', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const getUserProfile = createAsyncThunk(
    'user/details', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get('/profile', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const userEditProfile = createAsyncThunk(
    'user/edit', async (reqBody, thunkAPI) => {
        try {
            console.log("reqBody", reqBody)
            const { token, formData } = reqBody
            const response = await axios.put(`${process.env.REACT_APP_BASE_URL}profile`, formData, {
                headers: {
                    token: token,
                    'Content-Type': 'multipart/form-data',
                }
            });
            console.log("api call ", response)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const editProfile = createAsyncThunk(
    'editProfile', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.put('/profile', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const changePasswordAction = createAsyncThunk(
    'changePassword', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.patch('/change-password', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const dashboardAction = createAsyncThunk(
    'dashboard', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get('/dashboard', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const userDeleteAction = createAsyncThunk(
    'user-account', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.delete('/account', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

export {
    loginAction,
    signupAction,
    verifyOtpAction,
    ForgotPasswordAction,
    forgotOtpVerifyAction,
    resetpasswordAction,
    changePasswordAction,
    dashboardAction,
    userOtpVerification,
    userResendOtpVerification,
    userEditProfile,
    userDeleteAction,
    getUserProfile,
    editProfile
};