import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../Service/Api";

const addAddressAction = createAsyncThunk(
    'add/address', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/address', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const getListAddressAction = createAsyncThunk(
    'list/address', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get('/address', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const deleteAddressAction = createAsyncThunk(
    'delete/address', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.delete(`/address/${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const addressDetailsAction = createAsyncThunk(
    'details/address', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/address/${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const editAddressAction = createAsyncThunk(
    'edit/address', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.put(`/address/${reqBody.id}`, reqBody.formdata)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })


export {
    editAddressAction,
    addressDetailsAction,
    deleteAddressAction,
    getListAddressAction,
    addAddressAction

}