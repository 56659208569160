import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { ImagePath } from "../utilis/ImageUrl";

function AsNavFor({ data }) {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);
  const settings = {
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
  };
  return (
    <>
      <div className="slider-container">
        <Slider
          {...settings}
          asNavFor={nav2}
          ref={(slider) => (sliderRef1 = slider)}
          nav={false}
          dots={true}
        >
          {Array.isArray(data?.images) &&
            data?.images?.length > 0 &&
            data?.images.map((res, index) => {
              return (
                <>
                  <div className="slider-wrap" key={index}>
                    <img
                      src={
                        ImagePath(res) || require("../Assets/images/cakes.png")
                      }
                    />
                  </div>
                </>
              );
            })}
        </Slider>

        <Slider
          asNavFor={nav1}
          ref={(slider) => (sliderRef2 = slider)}
          slidesToShow={4}
          swipeToSlide={false}
          focusOnSelect={true}
          nav={false}
        >
          {Array.isArray(data?.images) &&
            data?.images?.length > 0 &&
            data?.images.map((res, index) => {
              return (
                <>
                  <div className="slider-nav" key={index}>
                    <img
                      style={{ cursor: "pointer" }}
                      src={
                        ImagePath(res) || require("../Assets/images/cakes.png")
                      }
                    />
                  </div>
                </>
              );
            })}
        </Slider>
      </div>
    </>
  );
}

export default AsNavFor;
