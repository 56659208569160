import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { changePasswordAction } from "../Redux/Action/AuthAction";
import { toast } from "react-toastify";

const ChangePasswordModal = ({ showPass, handleClosePass }) => {
  const dispatch = useDispatch();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Old Password is required"),
      newPassword: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("New Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: async (values) => {
      console.log(values, "values");
      let payload = {
        old_password: values?.oldPassword,
        new_password: values?.newPassword,
      };
      setIsLoader(true);
      await dispatch(changePasswordAction(payload)).then((res) => {
        console.log("changePasswordAction", res);
        if (res?.payload?.status === 200) {
          toast.success(res?.payload?.message);
          handleClose();
        } else {
          toast.error(res?.payload?.message);
        }
        setIsLoader(false);
      });
    },
  });

  const handleClose = () => {
    handleClosePass();
    formik.resetForm();
  };

  return (
    <Modal
      className="cmn-modal"
      show={showPass}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h2 className="heading-25 mb-4 ">Change Password</h2>

        <form onSubmit={formik.handleSubmit} className="cmn-form-fields">
          <Form.Group className="mb-3 form-inner">
            <img
              src={require("../Assets/images/email.svg").default}
              alt="email icon"
            />
            <Form.Control
              type={showOldPassword ? "text" : "password"}
              placeholder="Old Password"
              {...formik.getFieldProps("oldPassword")}
            />
            <button
              type="button"
              className="pass-show"
              onClick={() => setShowOldPassword((prev) => !prev)}
            >
              {showOldPassword ? (
                <img
                  src={require("../Assets/images/hide_password.svg").default}
                  alt="hide password"
                />
              ) : (
                <img
                  src={require("../Assets/images/eye.svg").default}
                  alt="show password"
                />
              )}
            </button>
            {formik.touched.oldPassword && formik.errors.oldPassword ? (
              <div className="text-danger">{formik.errors.oldPassword}</div>
            ) : null}
          </Form.Group>

          <Form.Group className="mb-3 form-inner">
            <img
              src={require("../Assets/images/email.svg").default}
              alt="email icon"
            />
            <Form.Control
              type={showNewPassword ? "text" : "password"}
              placeholder="New Password"
              {...formik.getFieldProps("newPassword")}
            />
            <button
              type="button"
              className="pass-show"
              onClick={() => setShowNewPassword((prev) => !prev)}
            >
              {showNewPassword ? (
                <img
                  src={require("../Assets/images/hide_password.svg").default}
                  alt="hide password"
                />
              ) : (
                <img
                  src={require("../Assets/images/eye.svg").default}
                  alt="show password"
                />
              )}
            </button>
            {formik.touched.newPassword && formik.errors.newPassword ? (
              <div className="text-danger">{formik.errors.newPassword}</div>
            ) : null}
          </Form.Group>

          <Form.Group className="mb-3 form-inner">
            <img
              src={require("../Assets/images/email.svg").default}
              alt="email icon"
            />
            <Form.Control
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm Password"
              {...formik.getFieldProps("confirmPassword")}
            />
            <button
              type="button"
              className="pass-show"
              onClick={() => setShowConfirmPassword((prev) => !prev)}
            >
              {showConfirmPassword ? (
                <img
                  src={require("../Assets/images/hide_password.svg").default}
                  alt="hide password"
                />
              ) : (
                <img
                  src={require("../Assets/images/eye.svg").default}
                  alt="show password"
                />
              )}
            </button>
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div className="text-danger">{formik.errors.confirmPassword}</div>
            ) : null}
          </Form.Group>

          <button type="submit" className="cmn-red-btn mb-0 w-100" disabled={isLoader ? true : false}>
            {isLoader &&
              <>
                <div class="spinner-border spinner-border-sm" role="status" >
                </div>{" "}
              </>
            }
            Save
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePasswordModal;
