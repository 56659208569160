import { useState, useEffect } from "react";

const apiKey = "AIzaSyBY7WePV2Eg7cigEfbsFvjs1GzEHx6GXVA"; 

const useCityFromLatLng = (lat, lng) => {
    const [city, setCity] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!lat || !lng || !apiKey) {
            setError("Latitude, longitude, and API key are required");
            setLoading(false);
            return;
        }

        const fetchCity = async () => {
            try {
                const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
                const response = await fetch(url);

                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }

                const data = await response.json();

                if (data.results.length > 0) {
                    const addressComponents = data.results[0].address_components;
                    const cityComponent = addressComponents.find(component =>
                        component.types.includes("locality")
                    );

                    setCity(cityComponent ? cityComponent.long_name : "Others");
                } else {
                    setError("City not found for the given coordinates");
                }
            } catch (err) {
                setError("Error fetching geocoding data");
            } finally {
                setLoading(false);
            }
        };

        fetchCity();
    }, [lat, lng]);

    return { city, error, loading };
};

export default useCityFromLatLng;
